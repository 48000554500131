import { DocumentIcon } from '@heroicons/react/24/outline';
import { ChatBubbleLeftRightIcon, PlusIcon, UserIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Button, Container, IconButton, Nav, Spinner, Toggle } from '~/src/components';
import { ProfileDrawer, ProfileDrawerProps, ProfileDrawerTabs } from '../../components';
import { usePieceworkers } from '../../hooks';
import './Availability.scss';

export const Availability = () => {
  const [viewAll, setViewAll] = React.useState(false);
  const [profileDrawerProps, setProfileDrawerProps] = React.useState<ProfileDrawerProps>({
    isOpen: false,
    onClose: closeProfileDrawer,
    tab: ProfileDrawerTabs.GENERAL,
    userId: '',
  });

  const { allPieceworkers, availablePieceworkers, pieceworkersLoading } = usePieceworkers();

  function openProfileDrawer(userId: string, tab: ProfileDrawerTabs = ProfileDrawerTabs.GENERAL) {
    setProfileDrawerProps({
      ...profileDrawerProps,
      isOpen: true,
      userId,
      tab,
    });
  }

  function closeProfileDrawer() {
    setProfileDrawerProps({
      ...profileDrawerProps,
      isOpen: false,
      userId: '',
      tab: ProfileDrawerTabs.GENERAL,
    });
  }

  function renderStatusBadge(node: any) {
    if (node.availabilities.edges.length > 0) {
      return <Badge label="Available" />;
    }
    return (
      <Badge
        label="No response"
        style={{
          backgroundColor: '#d68249',
        }}
      />
    );
  }

  function renderAvailablePieceworkers() {
    return (
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Pick up</th>
            <th></th>
            <th>Notes</th>
            <th>Send message</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {availablePieceworkers.map((edge: any, idx: number) => {
            const nextAvailability = edge.node.availabilities.edges[0].node;
            return (
              <tr key={idx}>
                <td className="Availability__inlineTableCell">
                  <IconButton
                    onClick={() => {
                      openProfileDrawer(edge.node.id);
                    }}
                  >
                    <UserIcon />
                  </IconButton>
                  <a
                    onClick={() => {
                      openProfileDrawer(edge.node.id);
                    }}
                  >
                    {edge.node.firstName} {edge.node.lastName}
                  </a>
                </td>
                <td>
                  {nextAvailability.availabilityDatetime
                    ? format(new Date(nextAvailability.availabilityDatetime), 'M/d/yyyy')
                    : ''}
                </td>
                <td>
                  {nextAvailability.availabilityDatetime
                    ? format(new Date(nextAvailability.availabilityDatetime), 'h:mm a')
                    : ''}
                </td>
                <td>
                  <IconButton
                    onClick={() => {
                      openProfileDrawer(edge.node.id, ProfileDrawerTabs.NOTES);
                    }}
                  >
                    <DocumentIcon />
                  </IconButton>
                </td>
                <td>
                  <Button color="dark" iconLeading={<ChatBubbleLeftRightIcon />} size="sm" variant="raised">
                    Send message
                  </Button>
                </td>
                <td>
                  <Link to={`/piecework/create-job?user_id=${edge.node.pk}`}>Assign job</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function renderAllPieceworkers() {
    return (
      <table className="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Notes</th>
            <th>Send message</th>
          </tr>
        </thead>
        <tbody>
          {allPieceworkers.map((edge: any, idx: number) => {
            return (
              <tr key={idx}>
                <td className="Availability__inlineTableCell">
                  <IconButton
                    onClick={() => {
                      openProfileDrawer(edge.node.id);
                    }}
                  >
                    <UserIcon />
                  </IconButton>
                  <a
                    onClick={() => {
                      openProfileDrawer(edge.node.id);
                    }}
                  >
                    {edge.node.firstName} {edge.node.lastName}
                  </a>
                </td>
                <td>{renderStatusBadge(edge.node)}</td>
                <td>
                  <IconButton
                    onClick={() => {
                      openProfileDrawer(edge.node.id, ProfileDrawerTabs.NOTES);
                    }}
                  >
                    <DocumentIcon />
                  </IconButton>
                </td>
                <td>
                  <Button color="dark" iconLeading={<ChatBubbleLeftRightIcon />} size="sm" variant="raised">
                    Send message
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  if (pieceworkersLoading) {
    return (
      <Container>
        <Spinner message="Loading availabilities..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Availability</title>
      </Helmet>
      <div className="Availability">
        <Nav title="Piecework" />
        <Container>
          <h4>Pieceworker availability</h4>
          <div className="Availability__inlineButtons">
            <Link to="/piecework/jobs" className="mr-4">
              <Button>Jobs</Button>
            </Link>
            <Button color="primary" variant="raised">
              Availability
            </Button>
            <div className="flex-1"></div>
            <Link to="/piecework/create-job">
              <Button iconLeading={<PlusIcon />} variant="outlined">
                Create new job
              </Button>
            </Link>
          </div>
          <div className="Availability__viewAll">
            <Toggle
              checked={viewAll}
              label={'View All'}
              onChange={() => {
                setViewAll(!viewAll);
              }}
            />
          </div>
          {viewAll ? <>{renderAllPieceworkers()}</> : <>{renderAvailablePieceworkers()}</>}
          <ProfileDrawer {...profileDrawerProps}></ProfileDrawer>
        </Container>
      </div>
    </>
  );
};
