import { useMutation, useQuery } from '@apollo/client';
import { Cog6ToothIcon, PlusIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Button, Container, Spinner } from '~/src/components';
import {
  ACCOUNT_ORDER_APPROVAL_MUTATION,
  ACCOUNT_QUERY,
  CREATE_ACCOUNT_CONTACT,
  EDIT_ACCOUNT_MUTATION,
  EDIT_ACCOUNT_NOTES_MUTATION,
  EDIT_CREDIT_AND_TERMS_MUTATION,
  SHIPPING_METHODS_QUERY,
  SHIPPING_PREFERENCE_MUTATION,
  STATUSES_QUERY,
  UPDATE_ACCOUNT_CONTACT,
} from '../../api';
import {
  AccessCodeModal,
  AccountNav,
  AddContactModal,
  CreditModal,
  DisplayTable,
  EditAccountModal,
  EditAccountNotesModal,
  EditContact,
  OrderModal,
  ShippingModal,
} from '../../components';
import { accountModalsReducer } from '../../reducers';
import './AccountDetail.scss';

export const AccountDetail = () => {
  const [activeNoteTab, setActiveNoteTab] = React.useState('account');
  const [account, setAccount] = React.useState<any>(null);

  const [modals, modalsDispatch] = React.useReducer(accountModalsReducer, {
    accessCode: {
      isOpen: false,
    },
    addContact: {
      isOpen: false,
    },
    credit: {
      isOpen: false,
      account: null,
    },
    editAccount: {
      isOpen: false,
      account: null,
      statuses: null,
    },
    EditAccountNotes: {
      isOpen: false,
      notes: '',
    },
    editContact: {
      isOpen: false,
      contact: null,
    },
    orderApproval: {
      isOpen: false,
      account: null,
    },
    shipping: {
      isOpen: false,
      account: null,
      shippingMethods: null,
    },
  });

  const { accountId } = useParams();

  const { loading: accountLoading, refetch: accountRefetch } = useQuery(ACCOUNT_QUERY, {
    variables: { id: accountId },
    onCompleted: (data) => setAccount(data.account),
  });
  const { data: shippingMethodsQuery } = useQuery(SHIPPING_METHODS_QUERY);
  const { data: statusesQuery } = useQuery(STATUSES_QUERY);

  let refetchQueries = [{ query: ACCOUNT_QUERY, variables: { id: accountId } }];
  const [createAccountContact] = useMutation(CREATE_ACCOUNT_CONTACT);
  const [updateOrderApproval] = useMutation(ACCOUNT_ORDER_APPROVAL_MUTATION);
  const [updateShippingPreference] = useMutation(SHIPPING_PREFERENCE_MUTATION);
  const [updateAccountNotes] = useMutation(EDIT_ACCOUNT_NOTES_MUTATION);
  const [updateAccountCreditAndTerms] = useMutation(EDIT_CREDIT_AND_TERMS_MUTATION);
  const [updateAccountInfo] = useMutation(EDIT_ACCOUNT_MUTATION);
  const [updateAccountContact] = useMutation(UPDATE_ACCOUNT_CONTACT);

  function renderStatusBadge(status: string) {
    switch (status) {
      case 'Active':
        return <Badge color="success" label={status} />;
      case 'Inactive':
        return <Badge color="warning" label={status} />;
      default:
        return status ? <Badge color="light" label={status} /> : <></>;
    }
  }

  function renderYesNoBadge(bool: boolean) {
    return <Badge color={bool ? 'success' : 'light'} label={bool ? 'Yes' : 'No'} />;
  }

  if (!account || accountLoading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spinner message="Loading..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Account #{accountId}</title>
      </Helmet>
      <div className="AccountDetail">
        <AccountNav account={account} />
        <Container className="AccountDetail__container">
          <div className="accountOverview">
            <div className="accountOverview__accountTopRow">
              <div className="accountInfo">
                <div className="accountInfo__accountStatus mb-4">
                  <div className="flex justify-between">
                    <span className="font-medium mb-2">Account #</span>
                    <span>{account.pk}</span>
                  </div>
                  <div className="flex align-center justify-between">
                    <span className="font-medium">Status</span>
                    {renderStatusBadge(account?.status?.status)}
                  </div>
                </div>
                <div className="accountInfo__accessCode">
                  <div
                    className="flex justify-between mb-2 accountInfo__accessCode__codeContainer"
                    onClick={() => modalsDispatch({ type: 'open', modal: 'accessCode' })}
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="font-medium text-primary">Access codes:</span>
                    <span className="text-primary">
                      <p>
                        {(() => {
                          let codes = account.accessCodes.edges.map((edge: any) => edge.node.code);
                          let maxLength = 3;
                          return codes.length
                            ? codes.slice(0, maxLength).reduce((a: string, b: string) => `${a}, ${b}`) +
                                (codes.length > maxLength ? `... ${codes.length - maxLength} more` : '')
                            : 'n/a';
                        })()}
                      </p>
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium">Active products:</span>
                    <span>
                      {account.accessCodes?.edges.length
                        ? account.accessCodes?.edges
                            .map((edge: any) => edge.node.featuredProducts?.edges.length)
                            .reduce((prev: number, curr: number) => prev + curr)
                        : 0}
                    </span>
                  </div>
                </div>
                <Button
                  className="accountInfo__editButton"
                  iconLeading={<Cog6ToothIcon />}
                  onClick={() =>
                    modalsDispatch({
                      type: 'open',
                      modal: 'editAccount',
                      props: {
                        account: account,
                        statuses: statusesQuery.statuses,
                      },
                    })
                  }
                  variant="outlined"
                >
                  Edit account info
                </Button>
              </div>

              <div className="accountAddress">
                <div className="flex justify-between mb-2">
                  <span>Address:</span>
                  <span>{account.address}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>City:</span>
                  <span>{account.city}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>State:</span>
                  <span>{account.state}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Postal:</span>
                  <span>{account.postal}</span>
                </div>
              </div>

              <div className="accountContacts">
                <div className="accountContacts__list">
                  {account.contacts.edges?.map((contact: any) => (
                    <div className="contact flex align-center justify-between" key={contact.node.id}>
                      <div className="contactName">
                        <span className="name">{contact.node.name}</span>
                        <span className="position">{contact.node.title}</span>
                      </div>
                      <Button
                        className="contactButton"
                        onClick={() =>
                          modalsDispatch({ type: 'open', modal: 'editContact', props: { contact: contact.node } })
                        }
                        rounded
                        size="sm"
                        variant="outlined"
                      >
                        View
                      </Button>
                    </div>
                  ))}
                  {!account.contacts.edges.length && (
                    <p className="font-italic m-4">No contacts exist on this account.</p>
                  )}
                </div>
                <div className="flex justify-end mt-4">
                  <Button
                    iconLeading={<PlusIcon />}
                    onClick={() => modalsDispatch({ type: 'open', modal: 'addContact' })}
                  >
                    Add
                  </Button>
                </div>
              </div>

              <div className="accountBilling">
                <div className="flex justify-between mb-2">
                  <span>Rep:</span>
                  <Link to={`../../reps/${account.rep.pk}`}>
                    {account.rep.user.firstName} {account.rep.user.lastName}
                  </Link>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Account Manager:</span>
                  <span>
                    {account.manager.user.firstName} {account.manager.user.lastName}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Distributor:</span>
                  <span>{renderYesNoBadge(account.isDistributor)}</span>
                </div>
                {/* <div className="flex justify-between mb-2">
                <span>Commission %</span>
                <span>N / A</span>
              </div> */}
              </div>
            </div>

            {/* notes tabs with conditional active status and content */}
            <div className="accountOverview__accountBottomRow">
              <div className="accountNotes">
                <div className="tabNav">
                  {['account', 'rep', 'billing'].map((tabName) => {
                    return (
                      <Button
                        color={activeNoteTab === tabName ? 'primary' : 'default'}
                        key={tabName}
                        onClick={() => setActiveNoteTab(tabName)}
                      >
                        {tabName.charAt(0).toLocaleUpperCase() + tabName.slice(1, tabName.length)}
                      </Button>
                    );
                  })}
                </div>
                <div className="flex justify-between">
                  <div className="tabContent">
                    <span className="tabContent__notes">
                      {activeNoteTab === 'account' && (account.notes || 'No notes specified')}
                      {activeNoteTab === 'rep' && (account.rep.notes || 'No notes specified')}
                      {activeNoteTab === 'billing' && (account.billing.notes || 'No notes specified')}
                    </span>
                    {activeNoteTab === 'account' && (
                      <Button
                        className="text-primary"
                        onClick={() =>
                          modalsDispatch({
                            type: 'open',
                            modal: 'editNotes',
                            props: {
                              notes: account.notes,
                            },
                          })
                        }
                      >
                        <span>Edit</span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className="creditAndTerms">
                <div className="flex justify-between titleAndEditRow mb-6">
                  <span className="font-size-16 font-medium">Credit and Terms</span>
                  <span
                    className="editButton"
                    onClick={() => modalsDispatch({ type: 'open', modal: 'credit', props: { account: account } })}
                  >
                    Edit
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Approved for credit:</span>
                  <div>{renderYesNoBadge(account.creditApp)}</div>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Payment Req:</span>
                  <span>{account.paymentReq?.type}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Term (Days):</span>
                  <span>{account.termsDays}</span>
                </div>
                <div className="flex justify-between">
                  <span>How Approved:</span>
                  <span className="howApprovedText">{account.creditAppHow || 'N / A'}</span>
                </div>
              </div>

              <div className="orderApproval">
                <div className="flex justify-between titleAndEditRow mb-6">
                  <span className="font-size-16 font-medium">Order Approval</span>
                  <span
                    className="editButton"
                    onClick={() => modalsDispatch({ type: 'open', modal: 'orderApproval', props: { account } })}
                  >
                    Edit
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>PO Required:</span>
                  {renderYesNoBadge(account.poNumberReq)}
                </div>
                <div className="flex justify-between">
                  <span>Signature Required:</span>
                  {renderYesNoBadge(account.approvalSigReq)}
                </div>
              </div>

              <div className="shippingPreference">
                <div className="flex justify-between titleAndEditRow mb-6">
                  <span className="font-size-16 font-medium">Shipping Preferences</span>
                  <span
                    className="editButton"
                    onClick={() =>
                      modalsDispatch({
                        type: 'open',
                        modal: 'shipping',
                        props: {
                          account: account,
                          shippingMethods: shippingMethodsQuery.shippings,
                        },
                      })
                    }
                  >
                    Edit
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Charge Shipping:</span>
                  {renderYesNoBadge(account.chargeShipping)}
                </div>
                <div className="flex justify-between mb-2">
                  <span>Ship 3rd Party:</span>
                  {renderYesNoBadge(account.shipThirdParty || false)}
                </div>
                {account.shipThirdParty && (
                  <>
                    <div className="flex justify-between mb-2">
                      <span>Shipping Carrier:</span>
                      <span>{account.shipping.method}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>3rd Party Account number:</span>
                      <span>{account.thirdPartyShipAcct}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <DisplayTable accountId={parseInt(accountId as string)} />

            {/************************** MODALS **************************/}
            <AccessCodeModal
              {...modals.accessCode}
              accountPk={account.pk}
              onClose={() => modalsDispatch({ type: 'close', modal: 'accessCode' })}
              refetch={accountRefetch}
            />
            <AddContactModal
              {...modals.addContact}
              onClose={() => modalsDispatch({ type: 'close', modal: 'addContact' })}
              onSubmit={(formData) => {
                createAccountContact({
                  variables: { id: accountId, updates: formData },
                  refetchQueries,
                }).then(() => modalsDispatch({ type: 'close', modal: 'addContact' }));
              }}
            />
            <CreditModal
              {...modals.credit}
              onClose={() => modalsDispatch({ type: 'close', modal: 'credit' })}
              onSubmit={(formData) => {
                updateAccountCreditAndTerms({
                  variables: { id: accountId, updates: formData },
                  refetchQueries,
                }).then(() => {
                  modalsDispatch({ type: 'close', modal: 'credit' });
                });
              }}
            />
            <EditAccountModal
              {...modals.editAccount}
              onClose={() => modalsDispatch({ type: 'close', modal: 'editAccount' })}
              onSubmit={(formData: any) => {
                updateAccountInfo({
                  variables: {
                    id: accountId,
                    updates: formData,
                  },
                  refetchQueries,
                });
              }}
            />
            <EditAccountNotesModal
              {...modals.editNotes}
              onClose={() => modalsDispatch({ type: 'close', modal: 'editNotes' })}
              onSubmit={(formData: string) => {
                updateAccountNotes({ variables: { id: accountId, updates: formData }, refetchQueries }).then(() => {
                  modalsDispatch({ type: 'close', modal: 'editNotes' });
                });
              }}
            />
            <EditContact
              {...modals.editContact}
              onClose={() => modalsDispatch({ type: 'close', modal: 'editContact' })}
              onSubmit={(val: any) => {
                updateAccountContact({
                  variables: {
                    id: modals.editContact.contact.pk,
                    updates: val,
                  },
                  refetchQueries,
                }).then(() => {
                  modalsDispatch({ type: 'close', modal: 'editContact' });
                });
              }}
            />
            <OrderModal
              {...modals.orderApproval}
              onClose={() => modalsDispatch({ type: 'close', modal: 'orderApproval' })}
              onSubmit={(formData) => {
                updateOrderApproval({ variables: { id: accountId, updates: formData }, refetchQueries }).then(() => {
                  modalsDispatch({ type: 'close', modal: 'orderApproval' });
                });
              }}
            />
            <ShippingModal
              {...modals.shipping}
              onClose={() => modalsDispatch({ type: 'close', modal: 'shipping' })}
              onSubmit={(formData: any) => {
                updateShippingPreference({
                  variables: { id: accountId, updates: formData },
                  refetchQueries,
                });
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
