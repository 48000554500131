import { useLazyQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import * as React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DataTable, DebouncedInput, Pagination, Spinner } from '~/src/components';
import {
  APPROVED_ORDERS_NOT_RELEASED_QUERY,
  COMPLETED_ORDERS_NOT_SHIPPED_QUERY,
  ORDERS_AWAITING_APPROVAL_QUERY,
  PRODUCTION_SUMMARY_BY_PRODUCT_GROUPS_QUERY,
} from '../../api';
import { ProductionSlugs, ReportsNav } from '../../components';
import './ProductionReports.scss';

type ProductionReportsProps = {};

type ProductionFilters = {
  search: string;
  page: number;
  sort: string;
};

const ProductionFiltersDefaults: ProductionFilters = {
  search: '',
  page: 1,
  sort: '',
};

const PAGE_SIZE = 30;

export const ProductionReports = (props: ProductionReportsProps) => {
  const { type } = useParams();
  const [searchbar, setSearchbar] = React.useState('');
  const [filters, setFilters] = React.useState<ProductionFilters>(ProductionFiltersDefaults);

  const [searchSummary, { data: summaryData, loading: summaryLoading }] = useLazyQuery(
    PRODUCTION_SUMMARY_BY_PRODUCT_GROUPS_QUERY
  );
  const [searchCompleted, { data: completedData, loading: completedLoading }] = useLazyQuery(
    COMPLETED_ORDERS_NOT_SHIPPED_QUERY
  );
  const [searchApproved, { data: approvedData, loading: approvedLoading }] = useLazyQuery(
    APPROVED_ORDERS_NOT_RELEASED_QUERY
  );
  const [searchAwaitingApproval, { data: awaitingApprovalData, loading: awaitingApprovalLoading }] =
    useLazyQuery(ORDERS_AWAITING_APPROVAL_QUERY);

  React.useEffect(() => {
    setSearchbar('');
    setFilters({
      ...ProductionFiltersDefaults,
      sort:
        type === ProductionSlugs.APPROVED
          ? '-order_date'
          : type === ProductionSlugs.COMPLETED
          ? '-completion_date'
          : type === ProductionSlugs.AWAITING_APPROVAL
          ? '-order_date'
          : '',
    });
    executeLazyQuery();
  }, [type]);

  React.useEffect(() => executeLazyQuery(), [filters]);

  function executeLazyQuery() {
    const variables = {
      first: PAGE_SIZE,
      offset: (filters.page - 1) * PAGE_SIZE,
      search: filters.search,
      sort: filters.sort,
    };

    type === ProductionSlugs.SUMMARY && searchSummary({ variables });
    type === ProductionSlugs.COMPLETED && searchCompleted({ variables });
    type === ProductionSlugs.APPROVED && searchApproved({ variables });
    type === ProductionSlugs.AWAITING_APPROVAL && searchAwaitingApproval({ variables });
  }

  function renderProductionSummaryPage() {
    const headers = [
      { label: 'Group', sort: '' },
      { label: 'Orders', sort: '' },
      { label: 'Pieces', sort: '' },
      { label: 'Average', sort: '' },
      { label: 'Oldest', sort: '' },
      { label: 'Subtotal', sort: '' },
    ];

    return (
      <div className="ProductionReports__body">
        <div className="ProductionReports__columnEqualizer">
          <div className="ProductionReports__body__header">
            <div className="ProductionReports__body__header__title">
              <h3>Production Summary by Product Groups</h3>
              <p>
                This summary includes all Order Details currently in production (those that have a{' '}
                <span style={{ fontStyle: 'italic' }}>Release</span> date but no{' '}
                <span style={{ fontStyle: 'italic' }}>Complete</span> date)
              </p>
            </div>
            {renderFilters()}
          </div>
          <div className="ProductionReports__body__table">
            {!summaryData || summaryLoading ? (
              <Spinner message="Loading product groups..." />
            ) : (
              <>
                <DataTable headers={headers}>
                  {summaryData.reportProductionSummaryByProductGroups.edges.map((edge: any, index: number) => (
                    <tr className="ProductionReports__body__table__compact" key={index}>
                      <td>
                        <Link to={`/`}>{edge.node.group}</Link>
                      </td>
                      <td>{edge.node.orders}</td>
                      <td>{Number(edge.node.pieces).toLocaleString('en-US')}</td>
                      <td>{Number(edge.node.average).toLocaleString('en-US', { maximumFractionDigits: 1 })}</td>
                      <td>{edge.node.oldest}</td>
                      <td>
                        {Number(edge.node.subtotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </td>
                    </tr>
                  ))}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderProductionSummaryTotals() {
    const getAttributeTotal = (attribute: string) => {
      return summaryData?.reportProductionSummaryByProductGroups.edges.reduce(
        (acc: number, edge: any) => acc + Number(edge.node[attribute]),
        0
      );
    };

    const getAverage = () => {
      const length = summaryData.reportProductionSummaryByProductGroups.edges.length;
      return length ? getAttributeTotal('average') / length : 0;
    };

    if (type !== ProductionSlugs.SUMMARY || !summaryData) return null;
    return (
      <div className="ProductionReports__productionSummaryTotals">
        <div className="ProductionReports__productionSummaryTotals__content">
          <span>Totals:</span>
          <span>{Number(getAttributeTotal('orders')).toLocaleString('en-US')}</span>
          <span>{Number(getAttributeTotal('pieces')).toLocaleString('en-US')}</span>
          <span>{Number(getAverage()).toLocaleString('en-US', { maximumFractionDigits: 1 })}</span>
          <span></span>
          <span>
            {Number(getAttributeTotal('subtotal')).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </span>
        </div>
      </div>
    );
  }

  function renderCompletedOrdersPage() {
    const headers = [
      { label: 'Invoice #', sort: 'id' },
      { label: 'Account', sort: 'account' },
      { label: 'Subtotal', sort: 'annotated_invoice_total' },
      { label: 'Completed', sort: 'completion_date' },
      { label: 'Hold date', sort: 'hold_date' },
      { label: 'Send by date', sort: 'send_by_date' },
      { label: 'Arrive by date', sort: 'arrive_by_date' },
      { label: 'Pay terms', sort: 'payment_req' },
    ];

    return (
      <div className="ProductionReports__body">
        <div className="ProductionReports__body__header">
          <div className="ProductionReports__body__header__title">
            <h3>Completed Orders Not Shipped</h3>
            <p>Tracks orders that have been completed but have yet to be shipped to the customer.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="ProductionReports__body__table">
          {!completedData || completedLoading ? (
            <Spinner message="Loading orders..." />
          ) : (
            <>
              <DataTable
                headers={headers}
                sort={filters.sort}
                onSortChange={(sort) => setFilters((p) => ({ ...p, sort }))}
              >
                {completedData.orders?.edges.map((edge: any) => (
                  <tr key={edge.node.id}>
                    <td>
                      <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
                    </td>
                    <td>
                      <Link to={`/accounts/${edge.node.account?.pk}`}>{edge.node.account?.name}</Link>
                    </td>
                    <td>
                      {Number(edge.node.invoiceTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                    <td>{edge.node.completionDate && format(new Date(edge.node.completionDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.holdDate && format(new Date(edge.node.holdDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.sendByDate && format(new Date(edge.node.sendByDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.arriveByDate && format(new Date(edge.node.arriveByDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.paymentReq?.type}</td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                hasNextPage={completedData.orders.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((prev) => ({ ...prev, page }))}
                page={filters.page}
                pageSize={PAGE_SIZE}
                totalNodes={completedData.orders.totalNodes}
                totalNodesOnPage={completedData.orders.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderApprovedOrdersPage() {
    const headers = [
      { label: 'Invoice #', sort: 'id' },
      { label: 'Account', sort: 'account' },
      { label: 'Order date', sort: 'order_date' },
      { label: 'Hold date', sort: 'hold_date' },
      { label: 'Ship by date', sort: 'ship_date' },
      { label: 'Invoice total', sort: 'annotated_invoice_total' },
    ];

    return (
      <div className="ProductionReports__body">
        <div className="ProductionReports__body__header">
          <div className="ProductionReports__body__header__title">
            <h3>Approved Orders Not Released</h3>
            <p>A list of orders that have been approved but not yet released.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="ProductionReports__body__table">
          {!approvedData || approvedLoading ? (
            <Spinner message="Loading orders..." />
          ) : (
            <>
              <DataTable
                headers={headers}
                sort={filters.sort}
                onSortChange={(sort) => setFilters((p) => ({ ...p, sort }))}
              >
                {approvedData.orders?.edges.map((edge: any) => (
                  <tr key={edge.node.id}>
                    <td>
                      <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
                    </td>
                    <td>
                      <Link to={`/accounts/${edge.node.account?.pk}`}>{edge.node.account?.name}</Link>
                    </td>
                    <td>{edge.node.orderDate && format(new Date(edge.node.orderDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.holdDate && format(new Date(edge.node.holdDate), 'M/d/yyyy')}</td>
                    <td>{edge.node.sendByDate && format(new Date(edge.node.sendByDate), 'M/d/yyyy')}</td>
                    <td>
                      {Number(edge.node.invoiceTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                hasNextPage={approvedData.orders.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((prev) => ({ ...prev, page }))}
                totalNodes={approvedData.orders.totalNodes}
                totalNodesOnPage={approvedData.orders.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderAwaitingApprovalPage() {
    const headers = [
      { label: 'Invoice #', sort: 'id' },
      { label: 'Account', sort: 'account' },
      { label: 'Order date', sort: 'order_date' },
      { label: 'Invoice total', sort: 'annotated_invoice_total' },
    ];

    return (
      <div className="ProductionReports__body">
        <div className="ProductionReports__body__header">
          <div className="ProductionReports__body__header__title">
            <h3>Orders Awaiting Approval</h3>
            <p>A list of orders that are awaiting approval.</p>
          </div>
          {renderFilters()}
        </div>
        <div className="ProductionReports__body__table">
          {!awaitingApprovalData || awaitingApprovalLoading ? (
            <Spinner message="Loading orders..." />
          ) : (
            <>
              <DataTable
                headers={headers}
                sort={filters.sort}
                onSortChange={(sort) => setFilters((p) => ({ ...p, sort }))}
              >
                {awaitingApprovalData.orders?.edges.map((edge: any) => (
                  <tr key={edge.node.id}>
                    <td>
                      <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
                    </td>
                    <td>
                      <Link to={`/accounts/${edge.node.account?.pk}`}>{edge.node.account?.name}</Link>
                    </td>
                    <td>{edge.node.orderDate && format(new Date(edge.node.orderDate), 'M/d/yyyy')}</td>
                    <td>
                      {Number(edge.node.invoiceTotal).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>
                ))}
              </DataTable>
              <Pagination
                page={filters.page}
                pageSize={PAGE_SIZE}
                hasNextPage={awaitingApprovalData.orders.pageInfo.hasNextPage}
                onPageChange={(page) => setFilters((prev) => ({ ...prev, page }))}
                totalNodes={awaitingApprovalData.orders.totalNodes}
                totalNodesOnPage={awaitingApprovalData.orders.totalNodesOnPage}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  function renderFilters() {
    return (
      <div className="ProductionReports__body__header__filters">
        <DebouncedInput
          value={searchbar}
          onChange={(e) => setSearchbar(e.target.value)}
          onDebounce={(str) => setFilters((p) => ({ ...p, search: str }))}
          placeholder="Search"
          iconLeading={<MagnifyingGlassIcon />}
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Production Reports</title>
      </Helmet>
      <div className="ProductionReports">
        <ReportsNav active="production" />
        <>
          {type === ProductionSlugs.SUMMARY && renderProductionSummaryPage()}
          {type === ProductionSlugs.COMPLETED && renderCompletedOrdersPage()}
          {type === ProductionSlugs.APPROVED && renderApprovedOrdersPage()}
          {type === ProductionSlugs.AWAITING_APPROVAL && renderAwaitingApprovalPage()}
        </>
        {renderProductionSummaryTotals()}
      </div>
    </>
  );
};
