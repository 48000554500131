import * as classNames from 'classnames';
import * as React from 'react';
import './Drawer.scss';

type DrawerProps = React.HTMLAttributes<HTMLDivElement> & {
  backdrop?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  testId?: string;
  closeOnBackdropClick?: boolean;
};

export const Drawer = ({
  backdrop,
  children,
  className,
  isOpen,
  onClose,
  testId,
  closeOnBackdropClick = true,
  ...rest
}: DrawerProps) => {
  const overlayClass = classNames('Drawer__overlay', {
    'Drawer__overlay--bg': backdrop,
  });

  const drawerClass = classNames('Drawer', className);

  return (
    <>
      {isOpen && (
        <>
          <div className={drawerClass} data-testid={testId} {...rest}>
            {children}
          </div>
          <div
            className={overlayClass}
            data-testid={`${testId}-overlay`}
            onClick={() => closeOnBackdropClick && onClose?.()}
          ></div>
        </>
      )}
    </>
  );
};
