import * as React from 'react';
import { Modal } from '../@shared/Modal/Modal';
import { Button } from '../@shared/Button/Button';
import './ConfirmationModal.scss';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  danger?: boolean;
  title?: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export function ConfirmationModal(props: ConfirmationModalProps) {
  function handleClose() {
    props.onClose();
  }

  function handleConfirm() {
    props.onConfirm();
    handleClose();
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleClose} className="ConfirmationModal" style={{ maxWidth: '500px' }}>
      <div className="ConfirmationModal">
        <div className="ConfirmationModal__header">
          <p>{props.title || 'Confirm'}</p>
        </div>
        <div className="ConfirmationModal__body">
          <p>{props.description || 'Are you sure you want to do this?'} </p>
          <div className="ConfirmationModal__buttons">
            <Button onClick={handleClose} variant="raised" color="light">
              {props.cancelButtonText || 'Cancel'}
            </Button>
            <Button onClick={handleConfirm} variant="raised" color={props.danger ? 'warn' : 'primary'}>
              {props.confirmButtonText || 'Confirm'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
