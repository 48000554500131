import { useMutation } from '@apollo/client';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Input, Modal, ShippingMethodSelect, Toggle } from '~/src/components';
import { ORDER_UPDATE_MUTATION } from '../../api';
import './ShippingPreferences.scss';

type ShippingPreferencesProps = {
  isOpen: boolean;
  onClose: () => void;
  order: any;
  refetch: () => void;
  proceedToShipping: boolean;
  onShippingConfirmation: (data: ShippingPreferencesFormData) => void;
};

type ShippingPreferencesFormData = {
  chargeShipping: boolean;
  shipThirdParty: boolean;
  shipping?: number;
  thirdPartyShipAcct: string;
  shippingCost?: string;
  tracking?: string;
  addTracking?: string;
};

const defaultFormData: ShippingPreferencesFormData = {
  chargeShipping: false,
  shipThirdParty: false,
  shipping: -1,
  thirdPartyShipAcct: '',
  shippingCost: '0.00',
  tracking: '',
  addTracking: '', 
};

export const ShippingPreferences = (props: ShippingPreferencesProps) => {
  const [formData, setFormData] = React.useState(defaultFormData);

  const [orderUpdate] = useMutation(ORDER_UPDATE_MUTATION);

  React.useEffect(() => {
    setFormData({
      chargeShipping: props.order.account?.chargeShipping,
      shipThirdParty: props.order.account?.shipThirdParty,
      shipping: props.order.account?.shipping?.pk,
      thirdPartyShipAcct: props.order.account?.thirdPartyShipAcct,
      shippingCost: props.order.account?.shippingCost,
      tracking: props.order.firstTracking,
      addTracking: props.order.addTracking,
    });
  }, []);

  function onCloseHandler() {
    props.onClose();
  }

  function onSubmitHandler() {
    orderUpdate({
      variables: {
        orderPk: props.order.pk,
        input: {
          shipping: formData.shipping,
          shippingCost: formData.shippingCost,
        },
      },
      onCompleted: props.refetch,
    }).then(() => onCloseHandler());
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ width: '480px', padding: '0' }}>
      <div className="ShippingPreferences">
        <div className="ShippingPreferences__header">
          <div>Confirm Shipping Preferences</div>
        </div>
        <div className="ShippingPreferences__body">
          <div className="ShippingPreferences__field">
            <label>Charge Shipping</label>
            {/* <Toggle checked={props.order.account.chargeShipping} /> */}
            <Toggle
              checked={formData.chargeShipping}
              onChange={() => setFormData((p) => ({ ...p, chargeShipping: !p.chargeShipping }))}
            />
          </div>
          <div className="ShippingPreferences__field">
            <label>Ship 3rd Party</label>
            {/* <Toggle checked={props.order.account.shipThirdParty} /> */}
            <Toggle
              checked={formData.shipThirdParty}
              onChange={() => setFormData((p) => ({ ...p, shipThirdParty: !p.shipThirdParty }))}
            />
          </div>
          <div className="ShippingPreferences__field">
            <label>Shipping Carrier</label>
            <ShippingMethodSelect
              onChange={(e) => setFormData({ ...formData, shipping: e.target.value })}
              value={formData.shipping}
            />
          </div>
          <div className="ShippingPreferences__field">
            <label>3rd Party Account Number</label>
            {/* <Input readOnly value={props.order.account.thirdPartyShipAcct} /> */}
            <Input
              value={formData.thirdPartyShipAcct}
              onChange={(e) => setFormData({ ...formData, thirdPartyShipAcct: e.target.value })}
            />
          </div>
          <div className="ShippingPreferences__field">
            <label>Shipping Cost</label>
            <Input
              iconLeading={<CurrencyDollarIcon />}
              onChange={(e) => setFormData({ ...formData, shippingCost: e.target.value })}
              value={formData.shippingCost}
            />
          </div>
          <div className="ShippingPreferences__field">
            <label>1st Tracking</label>
            <Input onChange={(e) => setFormData({ ...formData, tracking: e.target.value })} value={formData.tracking} />
          </div>
          <div className="ShippingPreferences__field">
            <label>Add Tracking</label>
            {/* <Input readOnly /> */}
            <Input
              onChange={(e) => setFormData({ ...formData, addTracking: e.target.value })}
              value={formData.addTracking}
            />
          </div>
        </div>
      </div>
      <div className="ShippingPreferences__actions">
        <Button className="mr-4" color="light" onClick={onCloseHandler} variant="raised">
          Cancel
        </Button>
        <Button
          color="primary"
          // disabled={!formData.shipping || !formData.shippingCost}
          // onClick={() => (onSubmitHandler(), props.proceedToShipping && props.onShippingConfirmation())}
          onClick={() => props.proceedToShipping && props.onShippingConfirmation(formData)}
          variant="raised"
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
