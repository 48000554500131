import { ChevronLeftIcon, ChevronRightIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Badge, Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, IconButton } from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';
import './PaymentApproval.scss';

type PaymentApprovalProps = {
  pieceworker: any;
  jobs: any[];
  isOpen: boolean;
  onApprove: (pieceworker: any, jobs: any[]) => void;
  onClose: () => void;
};

export const PaymentApproval = (props: PaymentApprovalProps) => {
  if (!props.jobs) {
    return null;
  }

  const [viewDetail, setViewDetail] = React.useState<any>(null);

  function renderJobList() {
    return (
      <div className="PaymentApproval__jobsList">
        {props.jobs.map((job, idx) => (
          <div key={idx} className="PaymentApproval__job">
            <div
              className="PaymentApproval__job__header"
              onClick={() => {
                setViewDetail(job);
              }}
            >
              <a>Job ID# {job.jobNum}</a>
              <IconButton color="primary">
                <ChevronRightIcon />
              </IconButton>
            </div>
            <div className="PaymentApproval__job__row">
              <label>Returned:</label>
              <div>{format(new Date(job.returnedDatetime), 'MM/dd/yyyy')}</div>
            </div>
            <div className="PaymentApproval__job__row">
              <label>Job total:</label>
              <div>{toFixed2(job.totalPrice)}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function renderProductGroupTasks(task: any) {
    return task.assignedProductGroupTasks.edges.map((edge: any, idx: number) => (
      <Badge key={idx} label={edge.node.name} />
    ));
  }

  function renderJobTasks(job: any) {
    return job.tasks.edges.map((edge: any, idx: number) => {
      return (
        <div className="PaymentApproval__jobDetail__card" key={idx}>
          <div className="PaymentApproval__jobDetail__task">
            <div className="PaymentApproval__jobDetail__task__row">
              <div className="PaymentApproval__jobDetail__task__field">
                <label>Sub-total:</label>
                <div>{toFixed2(edge.node.subtotal)}</div>
              </div>
            </div>
            <div className="PaymentApproval__jobDetail__task__row mt-2">
              <div className="PaymentApproval__jobDetail__task__field">
                <label>Invoice:</label>
                <div>{edge.node.orderDetails.order.pk}</div>
              </div>
              <div className="PaymentApproval__jobDetail__task__field">
                <label>Product:</label>
                <div>{edge.node.orderDetails.product.group.prefix}</div>
              </div>
            </div>
            <div className="PaymentApproval__jobDetail__task__row mt-2">
              <div className="PaymentApproval__jobDetail__task__field">
                <label>Order detail ID:</label>
                <div>{edge.node.orderDetails.pk}</div>
              </div>
              <div className="PaymentApproval__jobDetail__task__field">
                <label>Quantity:</label>
                <div>{edge.node.orderDetails.quantity}</div>
              </div>
            </div>
            <label>Tasks</label>
            <div className="PaymentApproval__jobDetail__task__badges">{renderProductGroupTasks(edge.node)}</div>
          </div>
        </div>
      );
    });
  }

  function renderJobDetail(job: any) {
    return (
      <div className="PaymentApproval__jobDetail">
        <h1>Job #{job.jobNum}</h1>
        <div className="PaymentApproval__jobDetail__total">
          <div className="PaymentApproval__jobDetail__total__icon">
            <div className="icon-wrapper icon-wrapper--green">
              <CurrencyDollarIcon />
            </div>
          </div>
          <div>
            <label>Job total</label>
            <h4>{toFixed2(job.totalPrice)}</h4>
          </div>
        </div>
        {renderJobTasks(job)}
      </div>
    );
  }

  return (
    <Drawer isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerHeader onClose={props.onClose} title="Payment Approval" />
      <DrawerContent>
        <div className="PaymentApproval__subHeader">
          {viewDetail && (
            <IconButton
              onClick={() => {
                setViewDetail(null);
              }}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <div className="PaymentApproval__subHeader__text">Completed jobs</div>
        </div>
        {viewDetail ? renderJobDetail(viewDetail) : renderJobList()}
      </DrawerContent>
      {!viewDetail && (
        <DrawerActions>
          <div className="flex-1"></div>
          <Button
            color="light"
            onClick={() => {
              props.onClose();
            }}
            variant="raised"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.onApprove(props.pieceworker, props.jobs);
            }}
            variant="raised"
          >
            Approve Jobs
          </Button>
        </DrawerActions>
      )}
    </Drawer>
  );
};
