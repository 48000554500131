import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './DisplayIndex.scss';

export const DisplayIndex = () => {
  return (
    <>
      <Helmet>
        <title>BW Portal - Displays</title>
      </Helmet>
      <div className="Displays">
        <h4 className="Displays__title">Display Builder</h4>
        <ul>
          <li>
            <Link to={'/displays/templates'}>Templates</Link>
          </li>
          <li>
            <Link to={'/displays/instances'}>Instances</Link>
          </li>
          <li>
            <Link to={'/displays/modules'}>Modules</Link>
          </li>
        </ul>
      </div>
    </>
  );
};
