import * as React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { ProductPreview } from '../ProductPreview';
import { ProductRow } from '../ProductRow';
import './NonDisplayTable.scss';

type NonDisplayTableProps = {
  onDelete: (orderDetailPk: number) => void;
  order: any;
  productOrderDetails: any;
  onUpdate: (orderDetail: any) => void;
  sort: string;
  onSort: (sort: string) => void;
};

export const NonDisplayTable = (props: NonDisplayTableProps) => {
  const [productPreviewProps, setProductPreviewProps] = React.useState<{
    isOpen: boolean;
    product?: any;
  }>({ isOpen: false });

  function renderOrderDetails() {
    return props.productOrderDetails.map((edge: any, index: number) => {
      return (
        <ProductRow
          key={index}
          orderDetails={edge.node}
          orderId={props.order.id}
          onDelete={(orderDetailsPk) => props.onDelete(orderDetailsPk)}
          openProductPreview={(product) => setProductPreviewProps({ isOpen: true, product })}
          onUpdate={props.onUpdate}
        />
      );
    });
  }

  return (
    <div className="NonDisplayTable">
      <table className="data-table OrderDetails__orderDetailsTable">
        <colgroup className="NonDisplayTable__colgroup">
          <col className="NonDisplayTable__colgroup__medium" />
          <col className="NonDisplayTable__colgroup__medium" />
          <col className="NonDisplayTable__colgroup__grow" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col className="NonDisplayTable__colgroup__input" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th onClick={() => props.onSort(props.sort === 'id' ? '-id' : 'id')} style={{ cursor: 'pointer' }}>
              <span className="flex align-center gap-2">
                {props.sort === 'id' ? (
                  <ChevronDownIcon style={{ width: '16px', minWidth: '16px', height: '16px', minHeight: '16px' }} />
                ) : null}
                {props.sort === '-id' ? (
                  <ChevronUpIcon style={{ width: '16px', minWidth: '16px', height: '16px', minHeight: '16px' }} />
                ) : null}
                Order detail ID
              </span>
            </th>
            <th
              onClick={() => props.onSort(props.sort === 'product__id' ? '-product__id' : 'product__id')}
              style={{ cursor: 'pointer' }}
            >
              <span className="flex align-center gap-2">
                {props.sort === 'product__id' ? (
                  <ChevronDownIcon style={{ width: '16px', minWidth: '16px', height: '16px', minHeight: '16px' }} />
                ) : null}
                {props.sort === '-product__id' ? (
                  <ChevronUpIcon style={{ width: '16px', minWidth: '16px', height: '16px', minHeight: '16px' }} />
                ) : null}
                Product #
              </span>
            </th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit price</th>
            <th>Commission</th>
            <th style={{ textAlign: 'right', paddingRight: '16px' }}>Sub-total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderOrderDetails()}</tbody>
      </table>
      <ProductPreview
        isOpen={productPreviewProps.isOpen}
        product={productPreviewProps.product}
        onClose={() => setProductPreviewProps({ isOpen: false })}
      />
    </div>
  );
};
