import { gql, useQuery } from '@apollo/client';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, Input, Modal, SearchPopover, Select } from '~/src/components';
import { CREATE_ORDER_REPS_QUERY } from '../../api';
import './OrderInfoModal.scss';

const PAYMENT_REQS_QUERY = gql`
  query orderDetails {
    paymentReqs {
      edges {
        node {
          id
          pk
          type
          altName
        }
      }
    }
  }
`;

type OrderInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (orderInfo: any) => void;
  order: any;
};

type OrderInfoFormData = {
  rep?: number;
  paymentReq?: number;
  termsDays?: number;
};

const OrderInfoFormDefaults: OrderInfoFormData = {
  rep: -1,
  paymentReq: -1,
  termsDays: 0,
};

export const OrderInfoModal = (props: OrderInfoModalProps) => {
  const { data: paymentReqsData } = useQuery(PAYMENT_REQS_QUERY);
  const [orderInfo, setOrderInfo] = React.useState(OrderInfoFormDefaults);
  const [searchRepsOpen, setSearchRepsOpen] = React.useState(false);
  const [selectedRep, setSelectedRep] = React.useState<any>(null);

  React.useEffect(() => {
    if (!props.isOpen) return;
    setOrderInfo({
      rep: props.order.rep?.pk,
      paymentReq: props.order.paymentReq?.pk,
      termsDays: props.order.termsDays,
    });
    setSelectedRep(props.order.rep);
  }, [props.isOpen]);

  function onCloseHandler() {
    setOrderInfo(OrderInfoFormDefaults);
    props.onClose();
  }

  function onSubmitHandler() {
    props.onSave(orderInfo);
    onCloseHandler();
  }

  function changeRep(rep: any) {
    setSelectedRep(rep);
    setOrderInfo((prev) => ({
      ...prev,
      rep: rep.pk,
    }));
  }

  function selectedRepName() {
    if (!selectedRep) {
      return '';
    }
    return `${selectedRep.user.firstName} ${selectedRep.user.lastName}`;
  }

  return (
    <Modal isOpen={props.isOpen} onClose={onCloseHandler} style={{ width: '360px', padding: '0', overflow: 'visible' }} className='orderInfoModal__modal'>
      <div className="orderInfoModal">
        <div className="flex justify-center modalGreyHeader">
          <p>Order Information</p>
        </div>
        <div className="orderInfoModal__body">
          <div className="flex justify-between">
            <label>Rep</label>
            <div className="relative">
              <Input
                iconLeading={<MagnifyingGlassIcon />}
                readOnly
                onClick={() => {
                  setSearchRepsOpen(true);
                }}
                value={selectedRepName()}
              />
              <SearchPopover
                isOpen={searchRepsOpen}
                onClose={() => {
                  setSearchRepsOpen(false);
                }}
                onChange={(rep) => {
                  changeRep(rep);
                }}
                popoverStyle={{ maxHeight: '400px', overflowY: 'auto', width: '280px' }}
                query={CREATE_ORDER_REPS_QUERY}
                queryArgument="search"
                queryField="reps"
                renderMatch={(match) => (
                  <>
                    {match.pk} - {match.user.firstName} {match.user.lastName}
                  </>
                )}
                searchLabel="Search reps"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <label>Terms</label>
            <Select
              value={orderInfo.paymentReq}
              options={paymentReqsData?.paymentReqs?.edges.map((edge: any) => ({
                value: edge.node.pk,
                label: edge.node.type,
              }))}
              onChange={(e) => setOrderInfo((prev) => ({ ...prev, paymentReq: e.target.value }))}
            />
          </div>
          <div className="flex justify-between">
            <label>Terms (days)</label>
            <Input
              className="orderInfoModal__input"
              onChange={(e) => setOrderInfo((prev) => ({ ...prev, termsDays: e.target.value ? parseInt(e.target.value) : 0 }))}
              value={orderInfo.termsDays}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end modalButtonEnd">
        <Button color="light" onClick={onCloseHandler} variant="raised">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmitHandler} variant="raised">
          Save
        </Button>
      </div>
    </Modal>
  );
};
