import { debounce } from 'lodash';
import * as React from 'react';

export function debounceRef(callback: (value: any, ...rest: any) => void, delay: number) {
  const ref = React.useRef(
    debounce((v: any, ...rest) => {
      callback(v, ...rest);
    }, delay)
  );

  return ref.current;
}
