import * as React from 'react';
import './ListPageHeader.scss';

export type ListPageHeaderProps = {
  title: string;
  children?: React.ReactNode;
};

export const ListPageHeader = (props: ListPageHeaderProps) => {
  return (
    <div className="ListPageHeader">
      <div className="flex align-center gap-4">
        <h4 className="ListPageHeader__title">{props.title}</h4>
        {props.children}
      </div>
      <p className="ListPageHeader__date">{new Intl.DateTimeFormat('en-US').format(new Date())}</p>
    </div>
  );
};
