import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import {
  addMonths,
  format,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  parse,
  setDate,
  subMonths,
} from 'date-fns';
import * as React from 'react';
import { IconButton } from '../IconButton';
import { Input, InputProps } from '../Input';
import './DatePicker.scss';
import classNames = require('classnames');

type DatePickerProps = {
  disabledBefore?: Date;
  disabledAfter?: Date;
  inputProps?: InputProps;
  onChange?: (e: Date | undefined) => void;
  style?: React.CSSProperties;
  testId?: string;
  value?: Date;
};

export const DatePicker = (props: DatePickerProps) => {
  const now = new Date();
  const initialDate = props.value ? props.value : now;

  const [inputValue, setInputValue] = React.useState(props.value ? format(props.value, 'M/d/yyyy') : '');
  const [calendarDate, setCalendarDate] = React.useState(initialDate);
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const daysInMonth = getDaysInMonth(calendarDate);
  const firstDayWeekday = getDay(setDate(calendarDate, 1));

  React.useEffect(() => {
    if (!props.value) {
      setInputValue('');
      return;
    }
    setCalendarDate(props.value);
    isValid(props.value) && setInputValue(format(props.value, 'M/d/yyyy'));
  }, [props.value]);

  function back() {
    const backDate = subMonths(calendarDate, 1);
    setCalendarDate(backDate);
  }

  function forward() {
    const forwardDate = addMonths(calendarDate, 1);
    setCalendarDate(forwardDate);
  }

  function handleClickDay(day: number) {
    const newSelected = new Date(getYear(calendarDate), getMonth(calendarDate), day);
    props.value && isSameDay(props.value, newSelected) ? props.onChange?.(undefined) : props.onChange?.(newSelected);
    setCalendarOpen(false);
  }

  function handleManualInput() {
    if (!inputValue) {
      props.onChange?.(undefined);
      return;
    }
    const newSelected = parse(inputValue, 'M/d/yyyy', new Date());
    isValid(newSelected)
      ? props.onChange?.(newSelected)
      : setInputValue(props.value ? format(props.value, 'M/d/yyyy') : '');
  }

  function dayIsDisabled(day: number) {
    if (!day) {
      return true;
    }
    const asDate = new Date(getYear(calendarDate), getMonth(calendarDate), day);
    let before = props.disabledBefore ? isBefore(asDate, props.disabledBefore) : false;
    let after = props.disabledAfter ? isAfter(asDate, props.disabledAfter) : false;
    return before || after;
  }

  function dayIsSelected(day: number) {
    if (!day || !props.value) {
      return false;
    }
    const date = new Date(getYear(calendarDate), getMonth(calendarDate), day);
    return isSameDay(date, props.value);
  }

  function daysInMonthArray() {
    let array = [];
    for (let i = 0; i < daysInMonth; i++) {
      array.push(i + 1);
    }
    return array;
  }

  function sliceIntoChunks(arr: any[], chunkSize: number) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  function weekChunk() {
    let dimArray = daysInMonthArray();
    let firstWeek = []; // e.g: [null, null, null, null, null, 1, 2]
    let lastDayInFirstWeek = 0;
    let rest = [];
    for (let i = 0; i < firstDayWeekday; i++) {
      firstWeek.push(null);
    }
    for (let i = firstDayWeekday; i < 7; i++) {
      firstWeek.push(dimArray[i - firstDayWeekday]);
      lastDayInFirstWeek = i - firstDayWeekday;
    }
    for (let i = lastDayInFirstWeek + 1; i < dimArray.length; i++) {
      rest.push(dimArray[i]);
    }
    return sliceIntoChunks(firstWeek.concat(rest), 7);
  }

  return (
    <>
      <div className="DatePicker__container" data-testid={`${props.testId}-container`}>
        <div className="DatePicker__icon" onClick={() => setCalendarOpen(true)}>
          <CalendarIcon />
        </div>
        <Input
          style={{ cursor: 'pointer' }}
          testId={`${props.testId}-input`}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => handleManualInput()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleManualInput();
              (e.target as HTMLElement).blur();
            }
            if (e.key === ';' && e.ctrlKey) {
              props.onChange?.(new Date());
            }
          }}
          {...props.inputProps}
        />
        {calendarOpen && (
          <>
            <div className="DatePicker" style={props.style}>
              <div className="DatePicker__navigation">
                <IconButton
                  onClick={() => {
                    back();
                  }}
                  testId={`${props.testId}-back`}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <div className="DatePicker__month" data-testid={`${props.testId}-month`}>
                  {format(calendarDate, 'MMM yyyy')}
                </div>
                <IconButton
                  onClick={() => {
                    forward();
                  }}
                  testId={`${props.testId}-forward`}
                >
                  <ChevronRightIcon />
                </IconButton>
              </div>
              <div className="DatePicker__calendar" data-testid={`${props.testId}-calendar`}>
                <table>
                  <thead>
                    <tr>
                      <th>Sun</th>
                      <th>Mon</th>
                      <th>Tue</th>
                      <th>Wed</th>
                      <th>Thu</th>
                      <th>Fri</th>
                      <th>Sat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {weekChunk().map((week, weekIndex) => (
                      <tr key={weekIndex}>
                        {week.map((day, dayIndex) => (
                          <td
                            key={dayIndex}
                            className={classNames({
                              'DatePicker--selected': dayIsSelected(day),
                              'DatePicker--disabled': dayIsDisabled(day),
                            })}
                            onClick={() => !dayIsDisabled(day) && handleClickDay(day)}
                          >
                            {day}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="DatePicker__overlay"
              data-testid={`${props.testId}-overlay`}
              onClick={() => setCalendarOpen(false)}
            ></div>
          </>
        )}
      </div>
    </>
  );
};
