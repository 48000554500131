import { useLazyQuery } from '@apollo/client';
import { ArrowPathIcon, EyeIcon, FunnelIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet';
import * as React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Container,
  DataTable,
  Input,
  ListPageHeader,
  Nav,
  Pagination,
  Select,
  Spinner,
} from '~/src/components';
import { formatDateString, toFixed2 } from '~/src/utils/formatting';
import { ORDERS_QUERY } from '../../api';
import { CreateOrderDrawer } from '../../components';
import './Orders.scss';
import { debounceRef } from '~src/utils/debounceRef';

const PAGE_SIZE = 30;

type OrdersFilters = {
  orderDate_Gte: Date | undefined;
  orderDate_Lte: Date | undefined;
  page: number;
  search: string;
  sort: string;
  status: string;
  account?: string;
  rep?: string;
};

const defaultFilters: OrdersFilters = {
  orderDate_Gte: undefined,
  orderDate_Lte: undefined,
  page: 1,
  search: '',
  sort: '-id',
  status: '',
};

const tableHeaders = [
  { label: 'Invoice', sort: 'id', size: 'sm' },
  { label: 'Account', sort: 'account', size: 'md' },
  { label: 'City', sort: 'city', size: 'sm' },
  { label: 'State', sort: 'state', size: 'sm' },
  { label: 'Order Rep', sort: 'rep', size: 'md' },
  { label: 'PO Number', sort: 'po_number', size: 'md' },
  { label: 'Order Date', sort: 'order_date', size: 'sm' },
  { label: 'Ship Date', sort: 'ship_date', size: 'sm' },
  { label: 'Paid Date', sort: 'paid_date', size: 'sm' },
  { label: 'Invoice Total', sort: 'annotated_invoice_total', size: 'sm' },
  { label: 'Status', sort: 'status', size: 'sm' },
];

export const Orders = () => {
  const [searchInput, setSearchInput] = React.useState(defaultFilters.search);

  const statusOptions = [
    '',
    'Awaiting Approval',
    'Unreleased',
    'In Production',
    'Ready to Ship',
    'Shipped',
    'Paid',
    'Future Order',
    'Canceled',
    'Unknown',
  ].map((status) => {
    return {
      label: status,
      value: status,
    };
  });

  const [createOrderOpen, setCreateOrderOpen] = React.useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchOrders, { data, loading }] = useLazyQuery(ORDERS_QUERY, {
    variables: {
      first: PAGE_SIZE,
      offset: (parseInt(searchParams.get('page') || '1') - 1) * PAGE_SIZE,
      // orderDate_Gte: searchParams.get('orderDate_Gte') ? new Date(searchParams.get('orderDate_Gte') || '') : undefined,
      // orderDate_Lte: searchParams.get('orderDate_Lte') ? new Date(searchParams.get('orderDate_Lte') || '') : undefined,
      search: searchParams.get('search') || '',
      sort: searchParams.get('sort') || '-id',
      status: searchParams.get('status') || '',
      account: searchParams.get('account') || '',
      rep: searchParams.get('rep') || '',
    },
  });

  React.useEffect(() => {
    setSearchInput(searchParams.get('search') || '');
    searchOrders();
  }, [searchParams]);

  function updateSearchParams(params: { [key: string]: any }) {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value.toString());
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  }

  const onSearchDebounce = (value: string) => {
    value ? searchParams.set('search', value) : searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const searchDebounce = debounceRef((val, func) => func(val), 500);

  function setStatusCallback(e: React.ChangeEvent<HTMLInputElement>) {
    updateSearchParams({ status: e.target.value });
  }

  function renderStatusBadge(status: string) {
    const COLOR_MAP: {
      [key: string]: string | undefined;
    } = {
      'Awaiting Approval': 'success',
      'Unreleased': 'light',
      'In Production': 'primary',
      'Ready to Ship': 'invertedPurple',
      'Shipped': 'invertedPrimary',
      'Paid': 'invertedSuccess',
      'Future Order': 'danger',
      'Canceled': 'invertedDanger',
    };
    return <Badge color={COLOR_MAP[status] as any} label={status} />;
  }

  function renderTableItems() {
    if (!data) return;
    return data.orders.edges.map((order: any, index: number) => {
      return (
        <tr key={index}>
          <td>
            <Link to={`/orders/${order.node.id}`}>{order.node.pk}</Link>
          </td>
          <td>
            {order.node.account &&
              renderFilterOrViewPopover(
                order.node.account?.name,
                () => updateSearchParams({ account: order.node.account?.id }),
                `/accounts/${order.node.account?.pk}`
              )}
          </td>
          <td>{order.node.account?.city}</td>
          <td>{order.node.account?.state}</td>
          <td>
            {order.node.rep &&
              renderFilterOrViewPopover(
                `${order.node.rep?.user.firstName} ${order.node.rep?.user.lastName}`,
                () => updateSearchParams({ rep: order.node.rep?.id }),
                `/reps/${order.node.rep?.pk}`
              )}
          </td>
          <td>{order.node.poNumber}</td>
          <td>{formatDateString(order.node.orderDate)}</td>
          <td>{formatDateString(order.node.shipDate)}</td>
          <td>{formatDateString(order.node.paidDate)}</td>
          <td>{toFixed2(order.node.invoiceTotal)}</td>
          <td>{renderStatusBadge(order.node.status)}</td>
        </tr>
      );
    });
  }

  function renderFilterOrViewPopover(label: string, onFilter: () => void, viewLink: string) {
    return (
      <div className="Orders__filterOrViewPopover">
        <a className="Orders__filterOrViewPopover__label">{label}</a>
        <div className="Orders__filterOrViewPopover__options">
          <a onClick={onFilter}>
            <FunnelIcon />
          </a>
          <Link to={viewLink} target="_blank">
            <EyeIcon />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Orders</title>
      </Helmet>
      <Nav />
      <Container className="Orders">
        <ListPageHeader title="View Orders">
          <Button
            className="ml-4"
            color="primary"
            iconLeading={<ArrowPathIcon />}
            onClick={() => searchOrders({ fetchPolicy: 'network-only' })}
            variant="raised"
          >
            Refresh
          </Button>
        </ListPageHeader>
        <div className="Orders__filters">
          <Input
            iconTrailing={<MagnifyingGlassIcon />}
            onChange={(e) => {
              setSearchInput(e.target.value);
              searchDebounce(e.target.value, onSearchDebounce);
            }}
            placeholder="Search"
            value={searchInput}
          />
          <div>
            <label>Order status</label>
            <Select
              options={statusOptions.map((o) => (o.value === '' ? { label: 'None', value: '' } : o))}
              onChange={setStatusCallback}
              value={searchParams.get('status') || ''}
            />
          </div>
          {/* <div>
            <label>Date range</label>
            <DatePicker
              onChange={(e) => setFilters((p) => ({ ...p, orderDate_Gte: e }))}
              value={filters.orderDate_Gte}
            />
          </div>
          <DatePicker value={filters.orderDate_Lte} onChange={(e) => setFilters((p) => ({ ...p, orderDate_Lte: e }))} /> */}
          <div className="flex-1"></div>
          {searchParams.size > 0 && (
            <Button
              color="dark"
              variant="raised"
              onClick={() => {
                searchParams.delete('page');
                searchParams.delete('sort');
                searchParams.delete('status');
                searchParams.delete('account');
                searchParams.delete('rep');
                searchParams.delete('search');
                setSearchParams(searchParams);
              }}
            >
              Clear Filters
            </Button>
          )}
          <Button color="primary" iconLeading={<PlusIcon />} onClick={() => setCreateOrderOpen(true)} variant="raised">
            New order
          </Button>
        </div>
        {loading || !data ? (
          <Container>
            <Spinner message="Loading orders..." />
          </Container>
        ) : (
          <>
            <DataTable
              headers={tableHeaders}
              sort={searchParams.get('sort') || '-id'}
              onSortChange={(sort) => updateSearchParams({ sort })}
            >
              {renderTableItems()}
            </DataTable>
            <Pagination
              page={parseInt(searchParams.get('page') || '1')}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => updateSearchParams({ page })}
              hasNextPage={data.orders?.pageInfo.hasNextPage}
              totalNodes={data.orders?.totalNodes}
              totalNodesOnPage={data.orders?.totalNodesOnPage}
            />
          </>
        )}
      </Container>
      <CreateOrderDrawer
        isOpen={createOrderOpen}
        onClose={() => {
          setCreateOrderOpen(false);
        }}
      />
    </>
  );
};
