import * as classNames from 'classnames';
import * as React from 'react';
import { Input } from '~src/components';
import { ReorderItem } from '../DisplayReorderDrawer';
import './DisplayReorderDrawerPanel.scss';

type DisplayReorderDrawerPanelProps = {
  panel: any;
  activeSection: number;
  reorderItems: ReorderItem[];
  setReorderItems: React.Dispatch<React.SetStateAction<ReorderItem[]>>;
  onEdgeHit: () => void;
};

export const DisplayReorderDrawerPanel = (props: DisplayReorderDrawerPanelProps) => {
  const { panel, activeSection, reorderItems, setReorderItems } = props;

  const { rows } = React.useMemo(
    () => getFlatPanelData(panel.sections.edges[activeSection].node),
    [panel, activeSection]
  );
  const rowCount = React.useMemo(() => rows?.length || 0, [rows]);
  const columnCount = React.useMemo(() => rows?.[0]?.positions?.edges?.length || 0, [rows]);

  const [focusedInput, setFocusedInput] = React.useState<[number, number] | null>(null);
  const inputRefs = React.useRef<(HTMLInputElement | null)[][]>(
    Array(rowCount)
      .fill(null)
      .map(() => Array(columnCount).fill(null))
  );

  React.useEffect(() => {
    inputRefs.current = Array(rowCount)
      .fill(null)
      .map(() => Array(columnCount).fill(null));
  }, [rowCount, columnCount]);

  React.useEffect(() => {
    if (focusedInput) {
      const [row, col] = focusedInput;
      const input = inputRefs.current[row][col];
      if (input) {
        input.focus();
        input.select();
      }
    }
  }, [focusedInput]);

  React.useEffect(() => {
    setFocusedInput([0, 0]);
  }, [panel, activeSection]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, row: number, col: number) => {
    let newRow = row;
    let newCol = col;

    switch (event.key) {
      case 'ArrowUp':
      case 'w':
      case 'W':
        if (row === 0) props.onEdgeHit();
        newRow = Math.max(0, row - 1);
        break;
      case 'ArrowDown':
      case 's':
      case 'S':
        if (row === rowCount - 1) props.onEdgeHit();
        newRow = Math.min(rowCount - 1, row + 1);
        break;
      case 'ArrowLeft':
      case 'a':
      case 'A':
        if (col === 0) props.onEdgeHit();
        newCol = Math.max(0, col - 1);
        break;
      case 'ArrowRight':
      case 'd':
      case 'D':
        if (col === columnCount - 1) props.onEdgeHit();
        newCol = Math.min(columnCount - 1, col + 1);
        break;
      default:
        return;
    }

    event.preventDefault();
    setFocusedInput([newRow, newCol]);
  };

  function getReorderValue(position: any, quantity: number) {
    const val: ReorderItem = {
      product: position.product.pk,
      position: position.pk,
      group: position.product.group.prefix,
      data: position.variables,
      quantity,
    };
    return val;
  }

  function getFlatPanelData(section: any) {
    if (!section) return {};
    const reducer: (arr: any[], attName: string) => any[] = (arr, attrName) =>
      arr.reduce((acc: any[], node: any) => {
        const nodeAttrArr = node[attrName]?.edges.map((e: any) => e.node) || [];
        return [...acc, ...nodeAttrArr];
      }, []);
    const sections: any[] = panel.sections.edges.map((e: any) => e.node);
    const modules = section.modules.edges.map((e: any) => e.node);
    const rows = reducer(modules, 'rows');
    const positions = reducer(rows, 'positions');
    return { sections, modules, rows, positions };
  }

  function renderPosition(position: any, rowIndex: number, colIndex: number) {
    const currentReorderValue = reorderItems.find((v) => v.position === position.pk);

    return (
      <div key={`${rowIndex}-${colIndex}`} className={classNames('DisplayReorderDrawerPanel__position')}>
        <Input
          disabled={!position.product}
          autoFocus={rowIndex === 0 && colIndex === 0}
          className={classNames('DisplayReorderDrawerPanel__position__input', {
            'DisplayReorderDrawerPanel__position__input--blue':
              currentReorderValue?.quantity && currentReorderValue.quantity < 20,
            'DisplayReorderDrawerPanel__position__input--yellow':
              currentReorderValue?.quantity && currentReorderValue.quantity >= 20 && currentReorderValue.quantity < 100,
            'DisplayReorderDrawerPanel__position__input--red':
              currentReorderValue?.quantity && currentReorderValue.quantity >= 100,
          })}
          value={currentReorderValue?.quantity || ''}
          onClick={(e) => {
            e.target instanceof HTMLInputElement && e.target.select();
          }}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (isNaN(Number(value)) || value.length > 3) return;
            const newReorderValue = getReorderValue(position, Number(e.target.value));
            currentReorderValue
              ? setReorderItems((prev) => prev.map((v) => (v.position === position.pk ? newReorderValue : v)))
              : setReorderItems((prev) => [...prev, newReorderValue]);
          }}
          onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
          ref={(el) => {
            if (inputRefs.current[rowIndex]) {
              inputRefs.current[rowIndex][colIndex] = el;
            }
          }}
        />
        <p className="DisplayReorderDrawerPanel__position__label">{position.label}</p>
      </div>
    );
  }

  const renderRow = (rowEdge: any) => {
    const rowIndex = rows?.findIndex((r: any) => rowEdge.node.pk === r.pk);
    if (rowIndex === undefined || rowIndex === -1) return null;
    return (
      <React.Fragment key={rowIndex}>
        {rows?.[rowIndex].positions.edges.map((pEdge: any, pIdx: number) => renderPosition(pEdge.node, rowIndex, pIdx))}
      </React.Fragment>
    );
  };

  if (!panel) return null;
  return (
    <div
      className="DisplayReorderDrawerPanel__section"
      style={{ gridTemplateColumns: `repeat(${columnCount}, minmax(100px, 250px))` }}
    >
      {panel.sections.edges[activeSection]?.node?.modules.edges.map((moduleEdge: any, moduleIndex: number) => (
        <React.Fragment key={moduleIndex}>
          {moduleEdge.node.rows.edges.map((rowEdge: any, rowIndex: number) => (
            <React.Fragment key={rowIndex}>{renderRow(rowEdge)}</React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
