import { CurrencyDollarIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { format, set, subDays } from 'date-fns';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Button, Container, DatePicker, FormLabel, IconButton, Input, Nav, Select } from '~/src/components';
import { useToast } from '~/src/features/toast';
import { tzOffset } from '~/src/utils/tzOffset';
import { JobTaskDrawer } from '../../components';
import { useAssigneeOptions, useJobDetailLazy, useJobMutations } from '../../hooks';
import './CreateJob.scss';

type Job = {
  assignee: number;
  pickupDatetime: Date;
  dueDate: Date;
  tasks: JobTask[];
};

type JobTask = {
  pk?: string;
  orderDetails: {
    pk: number;
    order: {
      pk: number;
    };
    product: {
      group: {
        name: string;
      };
    };
    quantity: number;
  };
  splitOrder: boolean;
  quantity: number;
  pricePerPiece: number;
  assignedProductGroupTasks: any[];
};

export const CreateJob = () => {
  const now = new Date();

  const [job, setJob] = React.useState<Job>({
    assignee: -1,
    pickupDatetime: set(now, {
      hours: 9,
      minutes: 0,
      seconds: 0,
    }),
    dueDate: new Date(),
    tasks: [],
  });
  const [jobTaskDrawer, setJobTaskDrawer] = React.useState<any>({
    initialTask: null,
    isOpen: false,
  });

  const navigate = useNavigate();
  const { error } = useToast();
  const { jobId } = useParams();
  const [searchParams] = useSearchParams();
  const { assigneeOptions } = useAssigneeOptions();
  const { createJob, createJobTask, deleteJobTask, updateJob, updateJobTask } = useJobMutations();
  const { getJob } = useJobDetailLazy();

  React.useEffect(() => {
    if (!jobId) {
      return;
    }
    getJob({
      variables: {
        id: jobId,
      },
    }).then((res) => {
      let existingJob = res.data.job;
      setJob({
        ...job,
        assignee: existingJob.assignee?.pk,
        pickupDatetime: new Date(existingJob.pickupDatetime),
        dueDate: tzOffset(new Date(existingJob.dueDate)),
        tasks: existingJob.tasks.edges.map((edge: any) => {
          let node = {
            ...edge.node,
            assignedProductGroupTasks: edge.node.assignedProductGroupTasks.edges.map((e: any) => e.node),
          };
          return node;
        }),
      });
    });
  }, [jobId]);

  React.useEffect(() => {
    // Auto select assignee from search params if it is one of the assignee options
    let userIdSearchParam = searchParams.get('user_id');
    if (userIdSearchParam) {
      if (assigneeOptions.map((option: any) => option.value.toString()).includes(userIdSearchParam)) {
        setJob({
          ...job,
          assignee: parseInt(userIdSearchParam),
        });
      }
    }
  }, [assigneeOptions]);

  function jobTaskDrawerClose() {
    setJobTaskDrawer({
      ...jobTaskDrawer,
      initialTask: null,
      isOpen: false,
    });
  }

  function jobTaskDrawerSubmit(taskData: any) {
    if (jobTaskDrawer.initialTask) {
      setJob((prev) => {
        let taskIndex = prev.tasks.findIndex((t) => t === jobTaskDrawer.initialTask);
        let tasks = prev.tasks;

        // if jobId, update existing task in database
        if (jobId) {
          updateJobTask({
            variables: {
              jobTaskPk: prev.tasks[taskIndex].pk,
              task: {
                orderDetails: taskData.orderDetails.pk,
                splitOrder: taskData.splitOrder,
                quantity: taskData.quantity,
                pricePerPiece: taskData.pricePerPiece,
                assignedProductGroupTasks: taskData.assignedProductGroupTasks.map((t: any) => t.pk),
              },
            },
          });
        }

        tasks[taskIndex] = taskData;
        return {
          ...job,
          tasks,
        };
      });
    } else {
      setJob((prev) => {
        let tasks = [...prev.tasks, taskData];

        // if jobId, create task in database
        if (jobId) {
          createJobTask({
            variables: {
              jobPk: jobId,
              task: {
                orderDetails: taskData.orderDetails.pk,
                splitOrder: taskData.splitOrder,
                quantity: taskData.quantity,
                pricePerPiece: taskData.pricePerPiece,
                assignedProductGroupTasks: taskData.assignedProductGroupTasks.map((t: any) => t.pk),
                subtotal: taskData.quantity * taskData.pricePerPiece,
              },
            },
          });
        }

        return {
          ...prev,
          tasks,
        };
      });
    }
    jobTaskDrawerClose();
  }

  function jobTaskDrawerRemove() {
    setJob((prev) => {
      let taskIndex = prev.tasks.findIndex((t) => t === jobTaskDrawer.initialTask);
      let tasks = prev.tasks.filter((_, i) => i !== taskIndex);

      if (jobId) {
        deleteJobTask({
          variables: {
            jobTaskPk: prev.tasks[taskIndex].pk,
          },
        });
      }

      return {
        ...prev,
        tasks,
      };
    });
    jobTaskDrawerClose();
  }

  function handleCreateJob() {
    let variables = {
      assignee: job.assignee,
      dueDate: format(job.dueDate, 'yyyy-MM-dd'),
      pickupDatetime: job.pickupDatetime,
      tasks: job.tasks.map((task) => {
        return {
          orderDetails: task.orderDetails.pk,
          splitOrder: task.splitOrder,
          quantity: task.quantity,
          pricePerPiece: task.pricePerPiece,
          assignedProductGroupTasks: task.assignedProductGroupTasks.map((t: any) => t.pk),
        };
      }),
    };
    createJob({
      variables,
    })
      .then((res) => {
        navigate(`/piecework/print-summary/${res.data.createJob.job?.pk}`);
      })
      .catch((err) => {
        error(err.message);
      });
  }

  function handleUpdateJob() {
    let variables = {
      jobPk: jobId,
      updates: {
        assignee: job.assignee,
        dueDate: format(job.dueDate, 'yyyy-MM-dd'),
        pickupDatetime: job.pickupDatetime,
      },
    };
    updateJob({
      variables,
    })
      .then((res) => {
        navigate(`/piecework/jobs/${res.data.updateJob.job?.pk}`);
      })
      .catch((err) => {
        error(err.message);
      });
  }

  function onSubmitFormHandler(e: any) {
    e.preventDefault();
    if (!jobId) {
      handleCreateJob();
      return;
    }
    handleUpdateJob();
  }

  function showTasks() {
    return !!job.assignee && job.assignee > 0 && !!job.pickupDatetime && !!job.dueDate;
  }

  function calcSubTotal(task: JobTask) {
    return task.quantity * task.pricePerPiece;
  }

  function calcTotal() {
    return job.tasks.reduce((prev, task) => {
      return (prev += calcSubTotal(task));
    }, 0);
  }

  function hasOneTask() {
    return job.tasks.length > 0;
  }

  function renderProductGroupTasks(task: JobTask) {
    let COLORS_MAP: { [key: number]: string } = {
      0: 'light',
      1: 'success',
      2: 'primary',
      3: 'purple',
      4: 'danger',
      5: 'warning',
    };

    return task.assignedProductGroupTasks.map((t: any, index: number) => (
      <Badge key={index} color={COLORS_MAP[index % 6] as any} label={t.name} />
    ));
  }

  function renderTasks() {
    if (job.tasks.length < 1) {
      return <div className="CreateJob__tasksEmpty">There's nothing added yet.</div>;
    }
    return job.tasks.map((task, idx) => {
      return (
        <table key={idx} className="CreateJob__task">
          <thead>
            <tr>
              <th className="CreateJob__task--md">Invoice #</th>
              <th className="CreateJob__task--lg">Order Detail #</th>
              <th className="centered CreateJob__task--md">Quantity</th>
              <th>Tasks</th>
              <th className="CreateJob__task--md">Sub-total</th>
              <th className="centered CreateJob__task--sm"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{task.orderDetails.order.pk}</td>
              <td>
                {task.orderDetails.pk} - {task.orderDetails.product.group.name}
              </td>
              <td className="centered">
                {task.splitOrder ? (
                  <>
                    {task.quantity} of {task.orderDetails.quantity || 0}
                  </>
                ) : (
                  <>{task.quantity}</>
                )}
              </td>
              <td className="CreateJob__task__badges">{renderProductGroupTasks(task)}</td>
              <td>${calcSubTotal(task).toFixed(2)}</td>
              <td className="centered">
                <IconButton
                  onClick={() => {
                    setJobTaskDrawer({
                      ...jobTaskDrawer,
                      initialTask: task,
                      isOpen: true,
                    });
                  }}
                >
                  <EllipsisVerticalIcon data-testid="rowEllipsis" />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      );
    });
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Create Job</title>
      </Helmet>
      <div className="CreateJob">
        <Nav title="Piecework" />
        <Container>
          <form onSubmit={onSubmitFormHandler}>
            <div className="CreateJob__header">
              <h4>{jobId ? `Edit Job #${jobId.substring(0, 8)}` : 'Assign Job'}</h4>
              <div>{format(now, 'M/d/yyyy')}</div>
            </div>
            <div className="CreateJob__inline">
              <div className="CreateJob__inline__group">
                <FormLabel>Assignee</FormLabel>
                <Select
                  fluid
                  options={assigneeOptions}
                  value={job.assignee}
                  onChange={(e) => {
                    setJob({
                      ...job,
                      assignee: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="CreateJob__inline__group">
                <FormLabel>Pickup date</FormLabel>
                <DatePicker
                  disabledBefore={subDays(now, 1)}
                  value={job.pickupDatetime}
                  onChange={(e) => {
                    if (!e) {
                      return;
                    }

                    setJob({
                      ...job,
                      pickupDatetime: set(job.pickupDatetime, {
                        year: e?.getFullYear(),
                        month: e?.getMonth(),
                        date: e?.getDate(),
                      }),
                    });
                  }}
                ></DatePicker>
              </div>
              <div className="CreateJob__inline__group">
                <FormLabel>Pickup time</FormLabel>
                <Input
                  type="time"
                  value={job.pickupDatetime.toTimeString().substring(0, 5)}
                  onChange={(e) => {
                    let split = e.target.value.split(':');
                    setJob({
                      ...job,
                      pickupDatetime: set(job.pickupDatetime, {
                        hours: parseInt(split[0]),
                        minutes: parseInt(split[1]),
                        seconds: 0,
                      }),
                    });
                  }}
                />
              </div>
              <div className="CreateJob__inline__group">
                <FormLabel>Return date</FormLabel>
                <DatePicker
                  disabledBefore={subDays(now, 1)}
                  value={job.dueDate}
                  onChange={(e) => {
                    if (!e) {
                      return;
                    }

                    setJob({ ...job, dueDate: e });
                  }}
                />
              </div>
              {hasOneTask() && (
                <>
                  <div className="CreateJob__inline__spacer"></div>
                  <div className="CreateJob__inline__group">
                    <FormLabel>Total</FormLabel>
                    <Input iconLeading={<CurrencyDollarIcon />} type="number" value={calcTotal().toFixed(2)} readOnly />
                  </div>
                </>
              )}
            </div>
            {showTasks() && (
              <>
                {renderTasks()}
                <Button
                  iconLeading={<PlusIcon />}
                  onClick={() => {
                    setJobTaskDrawer({
                      ...jobTaskDrawer,
                      initialTask: null,
                      isOpen: true,
                    });
                  }}
                  variant="outlined"
                >
                  Add to job
                </Button>
              </>
            )}
            {showTasks() && (
              <div className="CreateJob__buttonGroup">
                <Button
                  color="light"
                  onClick={() => {
                    navigate('/piecework/jobs');
                  }}
                  variant="raised"
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" variant="raised">
                  Submit
                </Button>
              </div>
            )}
          </form>
        </Container>
        <JobTaskDrawer
          isOpen={jobTaskDrawer.isOpen}
          initialTask={jobTaskDrawer.initialTask}
          onClose={jobTaskDrawerClose}
          onRemove={jobTaskDrawerRemove}
          onSubmit={jobTaskDrawerSubmit}
        />
      </div>
    </>
  );
};
