import { useLazyQuery, useQuery } from '@apollo/client';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, DataTable, Pagination, Spinner } from '~/src/components';
import { PRODUCT_ORDERS_QUERY, PRODUCT_QUERY } from '../../api';
import { ProductNav } from '../../components';
import './ProductHistory.scss';

const ProductHistoryTableHeaders = [
  { label: 'order id', size: 'sm' },
  { label: 'account name' },
  { label: 'order date', sort: 'order_date' },
  { label: 'ship date', sort: 'ship_date' },
  { label: 'purchase price' },
  { label: 'quantity' },
];

type ProductHistoryFilters = {
  page: number;
  pageSize: number;
  sort: string;
};

const defaultFilters: ProductHistoryFilters = {
  page: 1,
  pageSize: 100,
  sort: '-order_date',
};

export const ProductHistory = () => {
  const { productId } = useParams();

  const [filters, setFilters] = React.useState(defaultFilters);
  const variables = {
    first: filters.pageSize,
    offset: (filters.page - 1) * filters.pageSize,
    sort: filters.sort,
    productId,
  };

  const { data: productData, loading: productLoading } = useQuery(PRODUCT_QUERY, {
    variables: { id: productId },
    onCompleted: (res) =>
      loadProductOrders({
        variables: {
          ...variables,
          productPk: res?.product?.pk || -1,
        },
      }),
  });
  const [loadProductOrders, { data, loading }] = useLazyQuery(PRODUCT_ORDERS_QUERY);

  React.useEffect(() => {
    if (!productData?.product?.pk) return;
    loadProductOrders({
      variables: {
        ...variables,
        productPk: productData?.product?.pk || -1,
      },
    });
  }, [filters]);

  function formatDateString(str: string) {
    if (!str) return;
    let date = new Date(str);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  function formatPrice(num: number) {
    return `$${num.toFixed(2)}`;
  }

  if (!productData || productLoading) return <Spinner message="Loading product..." />;
  return (
    <>
      <Helmet>
        <title>BW Portal - Product History</title>
      </Helmet>
      <div className="ProductHistory">
        <ProductNav product={productData?.product} active="reports" />
        <Container style={{ flexGrow: '1', padding: '45px 40px', display: 'flex', flexDirection: 'column' }}>
          <div className="ProductHistory__header">
            <p>Product Order History</p>
          </div>
          <div style={{ flexGrow: '1' }}>
            <div className="ProductHistory__table">
              <DataTable
                headers={ProductHistoryTableHeaders}
                sort={filters.sort}
                onSortChange={(sort) =>
                  sort === filters.sort
                    ? setFilters((prev) => ({ ...prev, sort: `-${sort}` }))
                    : setFilters((prev) => ({ ...prev, sort }))
                }
              >
                {!data || loading ? (
                  <tr>
                    <td colSpan={6} style={{ padding: '24px' }}>
                      <Spinner message="Loading orders..." />
                    </td>
                  </tr>
                ) : (
                  data.orders?.edges.map((edge: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/orders/${edge.node.id}`}>{edge.node.pk}</Link>
                      </td>
                      <td>
                        <Link to={`/accounts/${edge.node.account?.pk}`}>{edge.node.account?.name}</Link>
                      </td>
                      <td>{formatDateString(edge.node.orderDate)}</td>
                      <td>{formatDateString(edge.node.shipDate)}</td>
                      <td>
                        {(() => {
                          let detailsArr = edge.node.orderDetails?.edges.map(
                            (detailsEdge: any) => detailsEdge.node?.unitPrice
                          );
                          return detailsArr.length < 2
                            ? formatPrice(parseFloat(detailsArr[0]))
                            : detailsArr.reduce(
                                (price1: string, price2: string) =>
                                  formatPrice(parseFloat(price1)) + ', ' + formatPrice(parseFloat(price2))
                              );
                        })()}
                      </td>
                      <td>
                        {(() => {
                          let detailsArr = edge.node.orderDetails?.edges.map(
                            (detailsEdge: any) => detailsEdge.node?.quantity
                          );
                          return detailsArr.length < 2
                            ? detailsArr[0]
                            : detailsArr.reduce((amount1: string, amount2: string) => amount1 + ', ' + amount2);
                        })()}
                      </td>
                    </tr>
                  ))
                )}
              </DataTable>
            </div>
          </div>
          <div className="ProductHistory__pagination">
            <Pagination
              page={filters.page}
              pageSize={filters.pageSize}
              onPageChange={(page) => {
                setFilters((prev) => ({ ...prev, page }));
              }}
              hasNextPage={data?.orders?.pageInfo.hasNextPage}
              totalNodes={data?.orders?.totalNodes || 0}
              totalNodesOnPage={data?.orders?.totalNodesOnPage || 0}
            />
          </div>
        </Container>
      </div>
    </>
  );
};
