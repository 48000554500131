import { useMutation, useQuery } from '@apollo/client';
import { ChartBarIcon, CloudArrowDownIcon, ExclamationTriangleIcon, FolderOpenIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Button, Container, Spinner, Textarea } from '~/src/components';
import { PRODUCT_QUERY, PRODUCT_UPDATE_MUTATION } from '../../api';
import { ArtNotesModal, ProductDetailsModal, ProductNav, TagCard, VariableFieldsDrawer } from '../../components';
import './ProductDetails.scss';

export const ProductDetails = () => {
  const { productId } = useParams();

  const [productDetailsModalOpen, setProductDetailsModalOpen] = React.useState(false);
  const [artNotesModalOpen, setArtNotesModalOpen] = React.useState(false);
  const [productNotes, setProductNotes] = React.useState<{ editing: boolean; notes: string }>({
    editing: false,
    notes: '',
  });
  const [variableFieldsDrawer, setVariableFieldsDrawer] = React.useState<{
    open: boolean;
    side: 'artFront' | 'artBack';
  }>({ open: false, side: 'artFront' });

  const { data, loading, refetch } = useQuery(PRODUCT_QUERY, {
    variables: { id: productId },
    onCompleted: (res) => setProductNotes((prev) => ({ ...prev, notes: res.product?.notes })),
  });
  const [updateProduct] = useMutation(PRODUCT_UPDATE_MUTATION, { onCompleted: () => refetch() });

  function dateStringFormatter(string: string) {
    if (!string) return;
    let date = new Date(string);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  function handleReactivate() {
    updateProduct({
      variables: { pk: data.product?.pk, updates: { active: true, cancelledDate: null, cancelledReason: null } },
    });
  }

  if (!data || loading) return <Spinner message="Loading product..." />;

  if (!data.product)
    return (
      <div className="flex justify-center py-8" style={{ fontStyle: 'italic' }}>
        Product not found
      </div>
    );

  return (
    <>
      <Helmet>
        <title>BW Portal - Product Details</title>
      </Helmet>
      <div className="ProductDetails">
        {!data.product?.cancelledDate ? (
          <ProductNav product={data.product} active="overview" />
        ) : (
          <div className="ProductDetails__cancelledBanner">
            <div className="ProductDetails__warningBanner">
              <span>
                <ExclamationTriangleIcon />
                <p>Product cancelled</p>
              </span>
              <p>
                Cancelled {dateStringFormatter(data.product?.cancelledDate)}
                {data.product.cancelledReason ? ` - ${data.product?.cancelledReason}` : ''}
              </p>
            </div>
            <Button color="dark" size="sm" variant="raised" onClick={handleReactivate}>
              Reactivate
            </Button>
          </div>
        )}
        <Container style={{ flexGrow: '1', padding: '40px 64px' }}>
          <div className="ProductDetails__info">
            <span className="ProductDetails__info__header">
              <p>Product Details</p>
              <a onClick={() => setProductDetailsModalOpen(true)}>Edit</a>
            </span>
            <div className="ProductDetails__info__content">
              <div className="ProductDetails__info__productInfo">
                <div className="ProductDetails__info__productInfo__content">
                  <span className="ProductDetails__keyValueSpan ProductDetails__keyValueSpan--bold">
                    <p>Product #:</p>
                    <p>{data.product?.name}</p>
                  </span>
                  <span className="ProductDetails__keyValueSpan ProductDetails__keyValueSpan--bold">
                    <p>Description:</p>
                    <p>{data.product?.description}</p>
                  </span>
                  <span className="ProductDetails__keyValueSpan">
                    <p>Product Group:</p>
                    <p>{data.product?.group.name}</p>
                  </span>
                  <span className="ProductDetails__keyValueSpan">
                    <p>Group Shape:</p>
                    <p>{data.product?.shape?.name}</p>
                  </span>
                  <span className="ProductDetails__keyValueSpan">
                    <p>Product UPC:</p>
                    <p>{data.product?.upc}</p>
                  </span>
                </div>
              </div>
              <div className="ProductDetails__info__batchInfo">
                <span className="ProductDetails__keyValueSpan ProductDetails__keyValueSpan--dark">
                  <p>Batch Size:</p>
                  <p>{data.product?.minQty}</p>
                </span>
                <span className="ProductDetails__keyValueSpan ProductDetails__keyValueSpan--dark">
                  <p>Production Ready:</p>
                  <p>{data.product?.readyForProduction ? 'Yes' : 'No'}</p>
                </span>
                <span className="ProductDetails__keyValueSpan ProductDetails__keyValueSpan--dark">
                  <p>Last Order:</p>
                  {data.product?.lastOrderDate && <p>{format(new Date(data.product?.lastOrderDate), 'M/d/yyyy')}</p>}
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Status:</p>
                  {data.product?.active ? (
                    <Badge color="success" label="Active" />
                  ) : (
                    <Badge color="danger" label="Inactive" />
                  )}
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Browsable:</p>
                  {data.product?.browsable ? (
                    <Badge color="success" label="Yes" />
                  ) : (
                    <Badge color="danger" label="No" />
                  )}
                </span>
                <span className="ProductDetails__keyValueSpan">
                  <p>Inventoried:</p>
                  {data.product?.acrInventoried ? (
                    <Badge color="success" label="Yes" />
                  ) : (
                    <Badge color="danger" label="No" />
                  )}
                </span>
              </div>
              <div className="ProductDetails__info__notes">
                <span className="ProductDetails__info__notes__header">
                  <p>Product Notes</p>
                  {!productNotes.editing && (
                    <a onClick={() => setProductNotes((prev) => ({ ...prev, editing: true }))}>Edit</a>
                  )}
                </span>
                {!productNotes.editing ? (
                  <div className="ProductDetails__info__notes__content">
                    {data.product?.notes || <p style={{ fontStyle: 'italic' }}>No notes specified</p>}
                  </div>
                ) : (
                  <div className="ProductDetails__info__notes__editing">
                    <Textarea
                      fluid
                      onChange={(e) => setProductNotes((prev) => ({ ...prev, notes: e.target.value }))}
                      value={productNotes.notes || ''}
                    />
                    <span>
                      <Button
                        color="light"
                        fluid
                        onClick={() => setProductNotes({ editing: false, notes: data.product?.notes })}
                        variant="raised"
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        fluid
                        onClick={() => {
                          updateProduct({
                            variables: { pk: data.product?.pk, updates: { notes: productNotes.notes } },
                            onCompleted: () => setProductNotes((prev) => ({ ...prev, editing: false })),
                          });
                        }}
                        variant="raised"
                      >
                        Save
                      </Button>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ProductDetails__art">
            <div className="ProductDetails__artWork">
              {(data.product?.artFront || data.product?.artBack) && (
                <span className="ProductDetails__artWork__header">
                  <p>Artwork</p>
                  <Button iconLeading={<CloudArrowDownIcon />} size="sm" variant="outlined">
                    Savedown
                  </Button>
                </span>
              )}
              {data.product?.artFront && (
                <div className="ProductDetails__artWork__artCard">
                  <div className="ProductDetails__artWork__artCard__title">
                    <p>Front Artwork</p>
                    <p>{data.product?.artFront.artNumber}</p>
                  </div>
                  <div className="ProductDetails__artWork__artCard__content">
                    <div>
                      <p>Final:</p>
                      <div className="ProductDetails__artWork__artCard__content__imgContainer">
                        {/* TODO: fix final front URL */}
                        <img
                          src={`${process.env.OPS_API}/images/${data.product?.pk}/render/?view=final_front`}
                          alt=""
                        />
                      </div>
                      <Button fluid iconTrailing={<FolderOpenIcon />} variant="outlined">
                        Art Folder
                      </Button>
                    </div>
                    <div>
                      <p>Product:</p>
                      <div className="ProductDetails__artWork__artCard__content__imgContainer">
                        <img
                          src={`${process.env.OPS_API}/images/${data.product?.pk}/render/?view=product_front`}
                          alt=""
                        />
                      </div>
                      <Button
                        fluid
                        iconTrailing={<ChartBarIcon />}
                        onClick={() => setVariableFieldsDrawer({ open: true, side: 'artFront' })}
                        variant="outlined"
                      >
                        Variable fields
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {data.product?.artBack && (
                <div className="ProductDetails__artWork__artCard">
                  <div className="ProductDetails__artWork__artCard__title">
                    <p>Back Artwork</p>
                    <p>{data.product?.artBack.artNumber}</p>
                  </div>
                  <div className="ProductDetails__artWork__artCard__content">
                    <div>
                      <p>Final:</p>
                      <div className="ProductDetails__artWork__artCard__content__imgContainer">
                        <img src={`${process.env.OPS_API}/images/${data.product?.pk}/render/?view=final_back`} alt="" />
                      </div>
                      <Button fluid iconTrailing={<FolderOpenIcon />} variant="outlined">
                        Art Folder
                      </Button>
                    </div>
                    <div>
                      <p>Product:</p>
                      <div className="ProductDetails__artWork__artCard__content__imgContainer">
                        <img
                          src={`${process.env.OPS_API}/images/${data.product?.pk}/render/?view=product_back`}
                          alt=""
                        />
                      </div>
                      <Button
                        fluid
                        iconTrailing={<ChartBarIcon />}
                        onClick={() => setVariableFieldsDrawer({ open: true, side: 'artBack' })}
                        variant="outlined"
                      >
                        Variable fields
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="ProductDetails__artInfo">
              <div className="ProductDetails__artInfo__notes">
                <span className="ProductDetails__artInfo__notes__header">
                  <p>Art notes</p>
                  <a onClick={() => setArtNotesModalOpen(true)}>Edit</a>
                </span>
                <div className="ProductDetails__artInfo__notes__card">
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Enter Date:</p>
                    <p>{dateStringFormatter(data.product?.enterDate)}</p>
                  </span>
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Artist:</p>
                    {data.product?.artist && (
                      <p>{`${data.product?.artist?.user.firstName} ${data.product?.artist?.user.lastName}`}</p>
                    )}
                  </span>
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Production Ready:</p>
                    <p>{dateStringFormatter(data.product?.readyForProduction)}</p>
                  </span>
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Ready to Review:</p>
                    <p>{dateStringFormatter(data.product?.readyForReview)}</p>
                  </span>
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Ready for Revisions:</p>
                    <p>{dateStringFormatter(data.product?.needsRevisionDate)}</p>
                  </span>
                  <span className="ProductDetails__artInfo__notes__card__valueSpan">
                    <p>Approval date:</p>
                    <p>{dateStringFormatter(data.product?.approvalDate)}</p>
                  </span>
                </div>
                {(data.product?.instructions || data.product?.revisionNotes) && (
                  <div className="ProductDetails__artInfo__notes__moreInfo">
                    {data.product?.instructions && (
                      <div>
                        <p>Instructions</p>
                        <div>
                          <p>{data.product?.instructions}</p>
                        </div>
                      </div>
                    )}
                    {data.product?.revisionNotes && (
                      <div>
                        <p>Revisions</p>
                        <div>
                          <p>{data.product?.revisionNotes}</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <TagCard
                tagsEdges={data.product?.tags.edges || []}
                onSave={(tags) => updateProduct({ variables: { pk: data.product?.pk, updates: { tags } } })}
              />
            </div>
          </div>
        </Container>
        <ProductDetailsModal
          isOpen={productDetailsModalOpen}
          onClose={() => setProductDetailsModalOpen(false)}
          product={data.product}
          onSave={(updates) => updateProduct({ variables: { pk: data.product?.pk, updates } })}
        />
        <ArtNotesModal
          isOpen={artNotesModalOpen}
          onClose={() => setArtNotesModalOpen(false)}
          product={data.product}
          onSave={(updates) => updateProduct({ variables: { pk: data.product?.pk, updates } })}
        />
        <VariableFieldsDrawer
          isOpen={variableFieldsDrawer.open}
          onClose={() => setVariableFieldsDrawer((prev) => ({ ...prev, open: false }))}
          product={data.product}
          frontOrBack={variableFieldsDrawer.side}
          productRefetch={refetch}
        />
      </div>
    </>
  );
};
