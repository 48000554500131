import { XMarkIcon } from '@heroicons/react/24/solid';
import * as classNames from 'classnames';
import * as React from 'react';
import { IconButton } from '../IconButton';

type DrawerHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
  title: string;
  hideCloseButton?: boolean;
};

export const DrawerHeader = ({ className, onClose, title, hideCloseButton }: DrawerHeaderProps) => {
  const headerClass = classNames('Drawer__header', className);

  return (
    <div className={headerClass}>
      <div className="Drawer__title">{title}</div>
      <div className="Drawer__header__spacer"></div>
      {!hideCloseButton && (
        <IconButton onClick={() => onClose?.()}>
          <XMarkIcon />
        </IconButton>
      )}
    </div>
  );
};
