import { UserIcon } from '@heroicons/react/24/solid';
import * as React from 'react';
import { Button, DataTable, IconButton } from '~/src/components';
import { toFixed2 } from '~/src/utils/formatting';

type ApproveJobsTableProps = {
  pendingApproval: any[];
  onOpenProfileDrawer: (id: string) => void;
  onOpenPaymentApproval: ({}: { jobs: any[]; pieceworker: any }) => void;
};

const tableHeaders = [
  { label: 'Name', size: 'md' },
  { label: 'Jobs completed', size: 'md' },
  { label: 'Amount to be paid', size: 'md' },
  { label: 'Pay approval', size: 'sm' },
];

export const ApproveJobsTable = (props: ApproveJobsTableProps) => {
  function calcPayAmount(assignedJobs: any) {
    return assignedJobs.reduce((acc: number, job: any) => acc + parseFloat(job.node.totalPrice), 0);
  }

  function renderPendingApproval() {
    return props.pendingApproval.map((edge, idx) => (
      <tr key={idx}>
        <td>
          <div className="flex align-center">
            <IconButton
              className="mr-4"
              onClick={() => {
                props.onOpenProfileDrawer(edge.node.id);
              }}
            >
              <UserIcon />
            </IconButton>
            <a
              onClick={() => {
                props.onOpenProfileDrawer(edge.node.id);
              }}
            >
              {edge.node.firstName} {edge.node.lastName}
            </a>
          </div>
        </td>
        <td>{edge.node.assignedJobs.edges.length}</td>
        <td>{toFixed2(calcPayAmount(edge.node.assignedJobs.edges))}</td>
        <td>
          <Button
            onClick={() => {
              props.onOpenPaymentApproval({
                jobs: edge.node.assignedJobs.edges.map((edge: any) => edge.node),
                pieceworker: edge.node,
              });
            }}
            size="sm"
            variant="raised"
          >
            Review
          </Button>
        </td>
      </tr>
    ));
  }

  return (
    <>
      <DataTable headers={tableHeaders}>{renderPendingApproval()}</DataTable>
    </>
  );
};
