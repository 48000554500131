import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ExclamationTriangleIcon as OutlinedWarningTriangle } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon as SolidWarningTriangle } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Badge, Button, Container, Modal, Nav, Spinner, TabItem, Tabs, Textarea } from '~/src/components';
import {
  ASSOCIATED_ORDERS_EMAIL,
  CUSTOM_ORDERS_UPDATE,
  CUSTOM_ORDER_QUERY,
  CUSTOM_ORDER_DETAIL_DELETE,
} from '../../api';
import { CancelOrderModal, CustomerInfoModal, OrderTable, ShippingModal } from '../../components';
import './EverynameOrderDetails.scss';

export const formatPriceString = (string?: string) => {
  if (!string) return undefined;
  return `$${parseFloat(string).toFixed(2)}`;
};

function formatDateString(string?: string) {
  if (!string) return undefined;
  let date = new Date(string);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export const EverynameOrderDetails = () => {
  const { orderId } = useParams();

  const [activeTab, setActiveTab] = React.useState('items');
  const [cancelOrderModal, setCancelOrderModal] = React.useState(false);

  const [orderNotes, setOrderNotes] = React.useState<{ open: boolean; value: string }>({ open: false, value: '' });
  const [customerInfo, setCustomerInfo] = React.useState<{ open: boolean; value: any }>({ open: false, value: {} });
  const [shippingInfo, setShippingInfo] = React.useState<{ open: boolean; value: string }>({ open: false, value: '' });
  const [deleteWarningInfo, setDeleteWarningInfo] = React.useState<{ open: boolean; value: string }>({
    open: false,
    value: '',
  });

  const { data, loading, refetch } = useQuery(CUSTOM_ORDER_QUERY, {
    variables: { orderId: parseInt(orderId as string) },
  });
  const [searchOrdersByEmail, { data: ordersByEmailData }] = useLazyQuery(ASSOCIATED_ORDERS_EMAIL);
  const [customOrdersUpdate] = useMutation(CUSTOM_ORDERS_UPDATE, { onCompleted: refetch });
  const [orderDetailDelete] = useMutation(CUSTOM_ORDER_DETAIL_DELETE, { onCompleted: refetch });

  React.useEffect(() => {
    if (!data) return;
    searchOrdersByEmail({ variables: { email: data.customOrder.email } });
    setOrderNotes((prev) => ({ ...prev, value: data.customOrder.notes }));
    setCustomerInfo((prev) => ({
      ...prev,
      value: {
        email: data.customOrder.email,
        firstName: data.customOrder.firstName,
        lastName: data.customOrder.lastName,
        address1: data.customOrder.address1,
        address2: data.customOrder.address2,
        city: data.customOrder.city,
        state: data.customOrder.state,
        country: data.customOrder.country,
        zipcode: data.customOrder.zipcode,
      },
    }));
    setShippingInfo((prev) => ({ ...prev, value: data.customOrder.tracking }));
  }, [data]);

  function sideInfo() {
    return (
      <div className="EverynameDetails__sideInfo">
        <div className="EverynameDetails__sideInfo__card">
          <p className="EverynameDetails__sideInfo__card__header">
            <strong>Order</strong> #{data.customOrder.pk}
          </p>
          <div className="EverynameDetails__sideInfo__grayBackground EverynameDetails__sideInfo__grayBackground--indent">
            <span className="EverynameDetails__sideInfo__valueSpan">
              <p>Account</p>
              <p>{data.customOrder.accessCode?.account?.name}</p>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan">
              <p>Access code:</p>
              <a>{data.customOrder.accessCode?.code}</a>
            </span>
          </div>
        </div>
        <div className="EverynameDetails__sideInfo__card EverynameDetails__sideInfo__card--paddingVertical">
          <span className="EverynameDetails__sideInfo__valueSpan">
            <p>Order Date</p>
            <p>{data.customOrder.orderDate && formatDateString(data.customOrder.orderDate)}</p>
          </span>
          <span className="EverynameDetails__sideInfo__valueSpan">
            <p>Ship Date</p>
            {data.customOrder.shipDate ? (
              <Badge color="success" label={formatDateString(data.customOrder.shipDate) || '0/0/0000'} />
            ) : (
              <Badge color="danger" label="Incomplete" />
            )}
          </span>
          <span className="EverynameDetails__sideInfo__valueSpan">
            <p>Tracking #</p>
            <p onClick={() => setShippingInfo((prev) => ({ ...prev, open: true }))} style={{ cursor: 'pointer' }}>
              {data.customOrder.tracking ? data.customOrder.tracking : 'None'}
            </p>
          </span>
          <span className="EverynameDetails__sideInfo__valueSpan">
            <p>Credit Date:</p>
            <p>{data.customOrder.creditAppliedDate && formatDateString(data.customOrder.creditAppliedDate)}</p>
          </span>
        </div>
        <div className="EverynameDetails__sideInfo__card">
          <span className="EverynameDetails__sideInfo__card__header EverynameDetails__sideInfo__valueSpan">
            <strong>Contact</strong>
            <a onClick={() => setCustomerInfo((prev) => ({ ...prev, open: true }))}>Edit</a>
          </span>
          <div className="EverynameDetails__sideInfo__card--padding">
            <span className="EverynameDetails__sideInfo__valueSpan">
              <p>Email:</p>
              <a href={`mailto:${data.customOrder.email}`}>{data.customOrder.email}</a>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan">
              <p>Customer:</p>
              <p>
                <strong>
                  {data.customOrder.firstName} {data.customOrder.lastName}
                </strong>
              </p>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan">
              <p>Address:</p>
              <span>
                <p>
                  {data.customOrder.address1} {data.customOrder.address2}
                </p>
                <p>
                  {data.customOrder.city}, {data.customOrder.state} {data.customOrder.zipcode}
                </p>
              </span>
            </span>
          </div>
        </div>
        <div className="EverynameDetails__sideInfo__card">
          <span className="EverynameDetails__sideInfo__card__header EverynameDetails__sideInfo__valueSpan">
            <strong>Order Notes</strong>
            {!orderNotes.open && <a onClick={() => setOrderNotes((prev) => ({ ...prev, open: true }))}>Edit</a>}
          </span>
          {orderNotes.open ? (
            <div className="EverynameDetails__sideInfo__editOrderNotes">
              <Textarea
                fluid
                onChange={(e) => setOrderNotes((prev) => ({ ...prev, value: e.target.value }))}
                value={orderNotes.value}
              />
              <div className="EverynameDetails__sideInfo__editOrderNotes__buttons">
                <Button
                  color="light"
                  fluid
                  onClick={() => setOrderNotes((prev) => ({ ...prev, open: false }))}
                  variant="raised"
                >
                  Cancel
                </Button>
                <Button
                  fluid
                  onClick={() => {
                    setOrderNotes((prev) => ({ ...prev, open: false }));
                    customOrdersUpdate({ variables: { pk: Number(orderId), values: { notes: orderNotes.value } } });
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : (
            <div className="EverynameDetails__sideInfo__grayBackground">
              {data.customOrder.notes || <p className="font-italic">No notes specified.</p>}
            </div>
          )}
        </div>
        <div className="EverynameDetails__sideInfo__card">
          <p className="EverynameDetails__sideInfo__card__header">
            <strong>Totals</strong>
          </p>
          <div className="EverynameDetails__sideInfo__grayBackground">
            <span className="EverynameDetails__sideInfo__valueSpan EverynameDetails__sideInfo__valueSpan--smallMargin">
              <p>Pieces:</p>
              <p>
                {(data.customOrder.orderDetails?.edges.length > 0 &&
                  data.customOrder.orderDetails?.edges
                    ?.map((edge: any) => edge.node.quantity)
                    .reduce((prev: number, next: number) => prev + next)) ||
                  0}
              </p>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan EverynameDetails__sideInfo__valueSpan--smallMargin">
              <p>Price:</p>
              <p>{formatPriceString(data.customOrder.itemTotal)}</p>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan EverynameDetails__sideInfo__valueSpan--smallMargin">
              <p>Shipping:</p>
              <p>{formatPriceString(data.customOrder.shippingCost)}</p>
            </span>
            <span className="EverynameDetails__sideInfo__valueSpan EverynameDetails__sideInfo__valueSpan--smallMargin">
              <p>Store Credit:</p>
              <p>
                (
                {formatPriceString(
                  data.customOrder.orderDetails?.edges.length > 0 &&
                    data.customOrder.orderDetails?.edges
                      ?.map((edge: any) => edge.node.creditMemo?.amount)
                      .reduce((prev: number, next: number) => prev + next)
                ) || '$0.00'}
                )
              </p>
            </span>
          </div>
        </div>
        <span className="EverynameDetails__sideInfo__valueSpan EverynameDetails__sideInfo__total">
          <p>Total receipt:</p>
          <p>{formatPriceString(data.customOrder.orderTotal)}</p>
        </span>
      </div>
    );
  }

  function tabs() {
    return (
      <div className="EverynameDetails__tabs">
        <Tabs className="EverynameDetails__tabs__tabContainer">
          <TabItem
            className="EverynameDetails__tabs__tab"
            active={activeTab === 'items'}
            onClick={() => setActiveTab('items')}
          >
            Items
          </TabItem>
          <TabItem
            className="EverynameDetails__tabs__tab"
            active={activeTab === 'history'}
            onClick={() => setActiveTab('history')}
          >
            History
            {ordersByEmailData?.customOrders.edges.length && (
              <Badge color="light" label={ordersByEmailData.customOrders.edges.length}></Badge>
            )}
          </TabItem>
        </Tabs>
        {activeTab === 'items' && (
          <OrderTable
            orderEdges={data.customOrder.orderDetails.edges}
            onDeleteClick={(id) => {
              setDeleteWarningInfo({ open: true, value: id });
            }}
          />
        )}
        {activeTab === 'history' && (
          <div>
            {ordersByEmailData?.customOrders.edges
              .slice()
              .sort((edge1: any, edge2: any) => {
                // sort edges by most recently added
                const date1 = new Date(edge1.node.shipDate);
                const date2 = new Date(edge2.node.shipDate);
                return date1 > date2 ? -1 : date2 > date1 ? 1 : 0;
              })
              .map((edge: any, index: number) => (
                <div key={index} className="EverynameDetails__tabs__tableContainer">
                  <div className="EverynameDetails__tabs__tableHeader">
                    <span>
                      <p>
                        Order #<Link to={`/everyname-orders/${edge.node.pk}`}>{edge.node.pk}</Link>
                      </p>
                      {edge.node.shipDate && (
                        <p>
                          Ship Date: <strong>{formatDateString(edge.node.shipDate)}</strong>
                        </p>
                      )}
                      <p>
                        Shipping: <strong>{formatPriceString(edge.node.shippingCost)}</strong>
                      </p>
                      <p>
                        Store Credit:{' '}
                        <strong>
                          {formatPriceString(
                            data.customOrder.orderDetails?.edges.length > 0 &&
                              edge.node.orderDetails?.edges
                                ?.map((edge: any) => edge.node.creditMemo?.amount)
                                .reduce((prev: number, next: number) => prev + next)
                          ) || '$0.00'}
                        </strong>
                      </p>
                    </span>
                    <p>
                      Total: <strong>{formatPriceString(edge.node.orderTotal)}</strong>
                    </p>
                  </div>
                  {<OrderTable orderEdges={edge.node.orderDetails.edges} />}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  function deleteWarning() {
    return (
      <Modal
        isOpen={deleteWarningInfo.open}
        onClose={() => setDeleteWarningInfo({ open: false, value: '' })}
        style={{ width: '512px' }}
      >
        <div className="EverynameDetails__deleteWarning">
          <div>
            <span className="EverynameDetails__deleteWarning__iconContainer">
              <OutlinedWarningTriangle />
            </span>
          </div>
          <div className="EverynameDetails__deleteWarning__content">
            <h1>Remove from order</h1>
            <p>Are you sure you want to remove this item from the order?</p>
            <span className="EverynameDetails__deleteWarning__content__buttons">
              <Button onClick={() => setDeleteWarningInfo({ open: false, value: '' })} variant="outlined">
                Cancel
              </Button>
              <Button
                color="warn"
                onClick={() => {
                  orderDetailDelete({ variables: { id: deleteWarningInfo.value } });
                  setDeleteWarningInfo({ open: false, value: '' });
                }}
                variant="raised"
              >
                Confirm
              </Button>
            </span>
          </div>
        </div>
      </Modal>
    );
  }

  if (!data || loading) {
    return (
      <Container>
        <Spinner message="Loading order data..." />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Everyname Order #{orderId}</title>
      </Helmet>
      <div className="EverynameDetails">
        {data.customOrder.cancelledDate && (
          <div className="EverynameDetails__cancelledBanner">
            <div className="EverynameDetails__cancelledBanner__left">
              <SolidWarningTriangle className="EverynameDetails__cancelledBanner__icon" />
              <span>
                <p className="EverynameDetails__cancelledBanner__title">
                  Order Cancelled {format(new Date(data.customOrder.cancelledDate), 'M/d/yyyy')}
                </p>
                {data.customOrder.cancelledReason && (
                  <p className="EverynameDetails__cancelledBanner__subtitle">{data.customOrder.cancelledReason}</p>
                )}
              </span>
            </div>
            <Button
              color="dark"
              variant="raised"
              size="sm"
              className="EverynameDetails__cancelledBanner__button"
              onClick={() => {
                customOrdersUpdate({
                  variables: { pk: Number(orderId), values: { cancelledDate: null, cancelledReason: null } },
                });
              }}
            >
              Reactivate
            </Button>
          </div>
        )}
        <Nav>
          <div className="EverynameDetails__nav">
            <h4>Custom Order</h4>
          </div>
        </Nav>
        <Container className="EverynameDetails__container">
          <div className="EverynameDetails__container__content">
            {sideInfo()}
            {tabs()}
          </div>
          <div className="EverynameDetails__container__cancelButtons">
            <Button color="warn" disabled={data.customOrder.cancelledDate} onClick={() => setCancelOrderModal(true)}>
              Cancel Order
            </Button>
            <Button onClick={() => setShippingInfo((prev) => ({ ...prev, open: true }))} variant="outlined">
              Send Shipment
            </Button>
          </div>
        </Container>
        {
          <>
            {deleteWarning()}
            <CustomerInfoModal
              isOpen={customerInfo.open}
              customerData={customerInfo.value}
              onSave={(customerInfo) =>
                customOrdersUpdate({ variables: { pk: Number(orderId), values: { customer: customerInfo } } })
              }
              onClose={() => setCustomerInfo((prev) => ({ ...prev, open: false }))}
            />
            <CancelOrderModal
              isOpen={cancelOrderModal}
              onClose={() => setCancelOrderModal(false)}
              onSave={(cancelledReason: string) =>
                customOrdersUpdate({
                  variables: {
                    pk: Number(orderId),
                    values: { cancelledDate: format(new Date(), 'yyyy-MM-dd'), cancelledReason },
                  },
                })
              }
            />
            <ShippingModal
              isOpen={shippingInfo.open}
              shippingInfo={shippingInfo.value}
              onClose={() => setShippingInfo((prev) => ({ ...prev, open: false }))}
              onSave={(trackingInfo) =>
                customOrdersUpdate({ variables: { pk: Number(orderId), values: { tracking: trackingInfo } } })
              }
            />
          </>
        }
      </div>
    </>
  );
};
