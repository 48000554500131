import { ChatBubbleLeftIcon, CheckIcon, PencilIcon, PrinterIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pieceworkLogo from '~/src/assets/img/piecework.png';
import { Badge, Button, Container, Modal, Nav, Spinner } from '~/src/components';
import { useToast } from '~/src/features/toast';
import { formatDateString } from '~/src/utils/formatting';
import { VerifyTask } from '../../components';
import { JobStatuses } from '../../enums';
import { useJobDetailLazy, useJobMutations } from '../../hooks';
import './JobDetail.scss';

export const JobDetail = () => {
  const [notifySentOpen, setNotifySentOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const navigate = useNavigate();
  const { jobId } = useParams();
  const { success, error } = useToast();
  const { getJob, job, jobLoading, jobError } = useJobDetailLazy();
  const { deleteJob } = useJobMutations();

  React.useEffect(() => {
    if (!jobId) {
      return;
    }
    getJob({
      variables: {
        id: jobId,
      },
    });
  }, [jobId]);

  function clickConfirmDelete() {
    deleteJob({
      variables: {
        confirm: true,
        jobPk: jobId,
      },
    })
      .then(() => {
        navigate('/piecework/jobs');
        success('The job has been deleted');
      })
      .catch((err) => {
        error(err.message);
      })
      .finally(() => {
        setConfirmDeleteOpen(false);
      });
  }

  function renderProductGroupTasks(task: any) {
    let COLORS_MAP: { [key: number]: string } = {
      0: 'light',
      1: 'success',
      2: 'primary',
      3: 'purple',
      4: 'danger',
      5: 'warning',
    };

    const assignedTasks = task.assignedProductGroupTasks.edges.map((edge: any) => edge.node);
    return assignedTasks.map((t: any, index: number) => {
      return <Badge key={index} color={COLORS_MAP[index % 6] as any} label={t.name} />;
    });
  }

  function confirmReadyTable() {
    return (
      <>
        <h5>Notify Pieceworker</h5>
        {job.tasks.edges.map((edge: any, idx: number) => {
          return (
            <table key={idx} className="JobDetail__table">
              <thead>
                <tr>
                  <th className="JobDetail__table--sm">Invoice #</th>
                  <th className="JobDetail__table--md">Order detail ID</th>
                  <th className="JobDetail__table--lg">Product Group</th>
                  <th className="JobDetail__table--sm">Quantity</th>
                  <th>Tasks</th>
                  <th className="JobDetail__table--sm">Per unit total</th>
                  <th className="JobDetail__table--sm">Sub-total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{edge.node.orderDetails.order.pk}</td>
                  <td>{edge.node.orderDetails.pk}</td>
                  <td>{edge.node.orderDetails.product.group.name}</td>
                  <td>{edge.node.quantity}</td>
                  <td className="JobDetail__table__badges">{renderProductGroupTasks(edge.node)}</td>
                  <td>${edge.node.pricePerPiece}</td>
                  <td>${edge.node.subtotal}</td>
                </tr>
              </tbody>
            </table>
          );
        })}
      </>
    );
  }

  function verifyJobTasksTable() {
    return (
      <>
        <h5>Verify Items</h5>
        <table className="JobDetail__table">
          <thead>
            <tr>
              <th>Invoice #</th>
              <th>Order detail ID</th>
              <th>Product group</th>
              <th>Quantity</th>
              <th>Confirm quantity</th>
              <th>Price unit total</th>
              <th>Sub-total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {job.tasks.edges.map((edge: any, idx: number) => {
              return (
                <VerifyTask
                  key={idx}
                  task={edge.node}
                  onVerify={() => {
                    getJob({
                      variables: {
                        id: jobId,
                      },
                    });
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  function paymentPendingTable() {
    return (
      <>
        <h5>Pending Payment</h5>
        <table className="JobDetail__table">
          <thead>
            <tr>
              <th>Invoice #</th>
              <th>Order detail ID</th>
              <th>Product Group</th>
              <th>Quantity</th>
              <th>Returned Quantity</th>
              <th>Price per piece</th>
              <th>Sub-total</th>
            </tr>
          </thead>
          <tbody>
            {job.tasks.edges.map((edge: any, idx: number) => {
              return (
                <tr key={idx}>
                  <td>{edge.node.orderDetails.order.pk}</td>
                  <td>{edge.node.orderDetails.pk}</td>
                  <td>{edge.node.orderDetails.product.group.name}</td>
                  <td>{edge.node.quantity}</td>
                  <td>{edge.node.returnedQuantity}</td>
                  <td>${edge.node.pricePerPiece}</td>
                  <td>${edge.node.subtotal}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  if (jobLoading) {
    return <Spinner message="Loading job..." />;
  }

  if (!job || jobError) {
    return (
      <Container>
        <p>Error loading job</p>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - Piecework Job #{jobId}</title>
      </Helmet>
      <div className="JobDetail">
        <Nav title="Piecework" />
        <Container>
          <div className="JobDetail__header">
            <div className="JobDetail__header__logo">
              <img src={pieceworkLogo}></img>
            </div>
            <div className="JobDetail__header__info">
              <h4 className="JobDetail__header__title">Piecework Job: {job.jobNum}</h4>
              <p className="JobDetail__header__assignee">
                Assignee: {job.assignee?.firstName} {job.assignee?.lastName}
              </p>
            </div>
            <div className="flex-1"></div>
            {!job.pickedUpDatetime && (
              <div className="JobDetail__header__actions">
                <Link to={`/piecework/print-summary/${job.pk}`}>
                  <Button iconLeading={<PrinterIcon />}>Print</Button>
                </Link>
                <Link to={`/piecework/edit-job/${job.pk}`}>
                  <Button iconLeading={<PencilIcon />}>Edit</Button>
                </Link>
                <Button
                  color="warn"
                  iconLeading={<TrashIcon />}
                  onClick={() => {
                    setConfirmDeleteOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        </Container>
        <div className="JobDetail__status">
          <div className="JobDetail__status__column">
            <div className="JobDetail__status__field">
              <label>Created by: </label>
              <span>
                {job.assignedBy.firstName} {job.assignedBy.lastName}
              </span>
            </div>
            {/* <div className="JobDetail__status__field">
            <label>Checked in by: </label>
            <span></span>
          </div> */}
          </div>
          <div className="JobDetail__status__column">
            <div className="JobDetail__status__field">
              <label>Pick up: </label>
              <span>
                {format(new Date(job.pickupDatetime), 'MM/dd/yyyy')} at{' '}
                {format(new Date(job.pickupDatetime), 'hh:mm a')}
              </span>
            </div>
            <div className="JobDetail__status__field">
              <label>Return: </label>
              <span>{formatDateString(job.dueDate)}</span>
            </div>
          </div>
          <div className="JobDetail__status__column">
            <div className="JobDetail__status__field">
              <label>Total: </label>
              <span>${job.totalPrice}</span>
            </div>
          </div>
        </div>
        <Container>
          {(job.status === JobStatuses.READY || job.status === JobStatuses.PICKUP) && confirmReadyTable()}
          {job.status === JobStatuses.PRODUCTION && verifyJobTasksTable()}
          {job.status === JobStatuses.PAYMENT && paymentPendingTable()}
        </Container>
        <div className="flex JobDetail-buttonHolder">
          <div className="JobDetail-buttonBottom">
            <Button
              color="light"
              onClick={() => {
                navigate('/piecework/jobs');
              }}
              variant="raised"
            >
              Close
            </Button>
          </div>
          <div className="JobDetail-buttonBottom">
            {(job.status === JobStatuses.READY || job.status === JobStatuses.PICKUP) && (
              <Button
                color="primary"
                iconLeading={<ChatBubbleLeftIcon />}
                onClick={() => {
                  setNotifySentOpen(true);
                }}
                variant="raised"
              >
                Notify Pieceworker
              </Button>
            )}
          </div>
        </div>
        <Modal
          isOpen={notifySentOpen}
          onClose={() => {
            setNotifySentOpen(false);
          }}
          style={{
            width: '384px',
          }}
        >
          <div className="JobDetail__notificationSent">
            <span className="icon-wrapper icon-wrapper--green">
              <CheckIcon />
            </span>
            <p className="font-size-18">Notification Sent</p>
            <Button
              fluid
              color="primary"
              onClick={() => {
                setNotifySentOpen(false);
              }}
              variant="raised"
            >
              Close
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={confirmDeleteOpen}
          onClose={() => {
            setConfirmDeleteOpen(false);
          }}
          style={{
            top: '200px',
            width: '320px',
          }}
        >
          <div className="JobDetail__deleteModal">
            <h3>Delete Job?</h3>
            <p>This operation cannot be undone</p>
            <div className="flex align-center justify-between">
              <Button
                color="light"
                onClick={() => {
                  setConfirmDeleteOpen(false);
                }}
                variant="raised"
              >
                Cancel
              </Button>
              <Button color="warn" onClick={clickConfirmDelete} variant="raised">
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
