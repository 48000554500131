import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PrinterIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useToast } from '~/src/features/toast';
import { formatPhoneNumber } from '~/src/utils/formatting';
import { Badge, DatePicker, Spinner, TabItem, Tabs } from '~/src/components';
import { DISPLAY_DETAIL_UPDATE_MUTATION } from '~/src/features/displays';
import avatarImg from '~/src/assets/img/Avatar_Placeholder.png';
import {
  REP_COMMISSION_REPORTS_QUERY,
  REP_QUERY,
  REP_UPDATE_MUTATION,
  REP_USER_PROFILE_UPDATE_MUTATION,
  REP_USER_UPDATE_MUTATION,
} from '../../api';
import { GroupListDrawer, RepArea, RepGeneralTab, RepListTab, RepNav, RepReordersTab } from '../../components';
import { DisplayFollowupDrawer } from '../../components/DisplayFollowupDrawer';
import './RepDetails.scss';

// TODO: wire up GroupListDrawer
export const RepDetails = () => {
  const { repPk } = useParams();

  const [rep, setRep] = React.useState<any>({});
  const [activeTab, setActiveTab] = React.useState<'general' | 'list' | 'reorders'>('general');
  const [groupListOpen, setGroupListOpen] = React.useState(false);
  const [followupDrawerOpen, setFollowupDrawerOpen] = React.useState(false);
  const [reportsDates, setReportsDates] = React.useState<{ start: Date; end: Date }>({
    start: new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1),
    end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
  });

  const { data, loading, refetch } = useQuery(REP_QUERY, { variables: { repPk: parseInt(repPk as string) } });
  const [getComissionReports] = useLazyQuery(REP_COMMISSION_REPORTS_QUERY);

  const [updateRep] = useMutation(REP_UPDATE_MUTATION);
  const [updateRepUser] = useMutation(REP_USER_UPDATE_MUTATION);
  const [updateRepUserProfile] = useMutation(REP_USER_PROFILE_UPDATE_MUTATION, {
    onError: (error) => errorToast(error.message),
  });
  const [displayDetailUpdate] = useMutation(DISPLAY_DETAIL_UPDATE_MUTATION, { onCompleted: refetch });

  const [searchParams, setSearchParams] = useSearchParams();

  const { error: errorToast } = useToast();

  React.useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) setActiveTab(tab as 'general' | 'list' | 'reorders');
  }, [searchParams]);

  React.useEffect(() => {
    if (!data) return;
    setRep(data.rep);
  }, [data]);

  function getCommissionReport(filename: string, shipped = false) {
    getComissionReports({
      variables: {
        rep: parseInt(repPk as string),
        start: format(reportsDates.start, 'yyyy-MM-dd'),
        end: format(reportsDates.end, 'yyyy-MM-dd'),
      },
      onCompleted: (data) => {
        downloadPdf(shipped ? data.reportRepCommissionShippedPdf : data.reportRepCommissionPdf, filename);
      },
    });
  }

  function downloadPdf(base64Pdf: string, filename: string) {
    // base64pdf is coming in with wierd format, "b'.....'" instead of just string
    if (base64Pdf.startsWith('b"')) base64Pdf = base64Pdf.replace('b"', '');
    base64Pdf = 'data:application/pdf;base64,' + base64Pdf;
    const downloadLink = document.createElement('a');
    downloadLink.href = base64Pdf;
    downloadLink.download = `${filename}.pdf`;
    downloadLink.click();
    downloadLink.remove();
  }

  if (!data || loading) {
    return <Spinner message="Loading rep..." />;
  }

  function getName() {
    return `${rep.user?.firstName} ${rep.user?.lastName}`;
  }

  return (
    <>
      <Helmet>
        <title>BW Portal - {getName()}</title>
      </Helmet>
      <div className="RepDetailsComponent">
        <RepNav rep={rep} />
        <div className="RepDetailsContainer">
          <div className="RepDetails">
            <div className="RepDetails__info">
              <div className="RepDetails__info__profile">
                <img
                  className="RepDetails__info__profile__img"
                  src={formatProfilePicture(rep.user?.profile?.profilePicture) || avatarImg}
                />
                <div className="RepDetails__info__profile__titles">
                  <h3>
                    {rep.user?.firstName} {rep.user?.lastName}
                  </h3>
                  <p onClick={() => setGroupListOpen(true)}>
                    {rep.repType === 'REP' && 'Rep'}
                    {rep.repType === 'REP_GROUP_LEADER' && 'Rep Group Leader'}
                    {rep.repType === 'COMPANY_SUB_REP' && 'Company Sub Rep'}
                  </p>
                  <Badge
                    className="RepDetails__info__profile__titles__badge"
                    color={rep.active ? 'success' : 'warning'}
                    label={rep.active ? 'Active' : 'Inactive'}
                    style={{
                      marginTop: '16px',
                    }}
                  />
                </div>
              </div>
              <div className="RepDetails__info__contact">
                <div className="RepDetails__info__contact__company">
                  <p>Company:</p>
                  <p>{rep.company}</p>
                </div>
                {rep.user?.profile?.phone && (
                  <div className="RepDetails__info__contact__phone">
                    <p>Phone:</p>
                    <a href={'tel:' + rep.user?.profile.phone}>{formatPhoneNumber(rep.user?.profile.phone)}</a>
                  </div>
                )}
                {rep.user?.email && (
                  <div className="RepDetails__info__contact__email">
                    <p>Email:</p>
                    <a href={'mailto:' + rep.user?.email}>{rep.user?.email}</a>
                  </div>
                )}
                <div className="RepDetails__info__contact__address">
                  <p>Address:</p>
                  <span>
                    <p>{rep.user?.profile?.address}</p>
                    <p>
                      {rep.user?.profile?.city}, {rep.user?.profile?.state} {rep.user?.profile?.zip}
                    </p>
                  </span>
                </div>
              </div>
              <RepArea
                value={rep.areaDescription}
                label="Rep Area"
                updateData={(notes: string) =>
                  updateRep({ variables: { pk: Number(repPk), rep: { areaDescription: notes } } })
                }
              />
              <RepArea
                value={rep.notes}
                label="Additional notes"
                updateData={(notes: string) => updateRep({ variables: { pk: Number(repPk), rep: { notes } } })}
              />
              <div className="RepDetails__info__reports">
                <div className="RepDetails__info__reports__header">
                  <PrinterIcon />
                  <p>Reports</p>
                </div>
                <div className="RepDetails__info__reports__divider"></div>
                <div className="RepDetails__info__reports__dates">
                  <span>
                    <p>Start Date</p>
                    <DatePicker
                      value={reportsDates.start}
                      onChange={(date) => {
                        date && setReportsDates((p) => ({ ...p, start: date }));
                      }}
                    />
                  </span>
                  <span>
                    <p>End Date</p>
                    <DatePicker
                      value={reportsDates.end}
                      onChange={(date) => {
                        date && setReportsDates((p) => ({ ...p, end: date }));
                      }}
                    />
                  </span>
                </div>
                <div className="RepDetails__info__reports__list">
                  <div>
                    <p>Rep Commission</p>
                    <a onClick={() => getCommissionReport(`${rep.user.lastName}_CommissionReport`)}>Print</a>
                  </div>
                  <div>
                    <p>Rep Commission Shipped</p>
                    <a onClick={() => getCommissionReport(`${rep.user.lastName}_CommissionShippedReport`, true)}>
                      Print
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="RepDetails__tabs">
              <div className="RepDetails__tabs__container">
                <Tabs>
                  <TabItem
                    className="RepDetails__tab"
                    active={activeTab === 'general'}
                    onClick={() => {
                      setSearchParams({ tab: 'general' });
                    }}
                  >
                    General
                  </TabItem>
                  <TabItem
                    className="RepDetails__tab"
                    active={activeTab === 'list'}
                    onClick={() => {
                      setSearchParams({ tab: 'list' });
                    }}
                  >
                    Display list
                  </TabItem>
                </Tabs>
                {activeTab === 'reorders' && <a onClick={() => setFollowupDrawerOpen(true)}>Display Follow-up</a>}
              </div>
              <div className="RepDetails__tabs__contentContainer">
                {activeTab === 'general' && (
                  <RepGeneralTab
                    rep={rep}
                    updateRep={(newData) => updateRep({ variables: { pk: Number(repPk), rep: newData } })}
                    updateRepUser={(newData) => updateRepUser({ variables: { pk: rep.user?.pk, ...newData } })}
                    updateRepUserProfile={(newData) =>
                      updateRepUserProfile({ variables: { pk: rep.user?.profile?.pk, updates: newData } })
                    }
                    refetch={() => refetch()}
                  />
                )}
                {activeTab === 'list' && (
                  <RepListTab
                    data={data}
                    onEditModalSave={(data) => {
                      if (data.pk) displayDetailUpdate({ variables: data });
                    }}
                  />
                )}
                {activeTab === 'reorders' && <RepReordersTab data={data} />}
              </div>
            </div>
          </div>
        </div>
        <GroupListDrawer isOpen={groupListOpen} onClose={() => setGroupListOpen(false)} />
        <DisplayFollowupDrawer
          isOpen={followupDrawerOpen}
          onClose={() => setFollowupDrawerOpen(false)}
          displays={
            rep.account?.edges.reduce(
              (acc: any[], { node }: any) => [...acc, ...node.displaydetailsSet?.edges.map(({ node }: any) => node)],
              []
            ) || []
          }
        />
      </div>
    </>
  );
};

export const formatProfilePicture = (profilePicture: { file: string; type: string; name: string } | undefined) => {
  if (!profilePicture) return;
  return `data:${profilePicture.type};base64,${profilePicture.file}`;
};
